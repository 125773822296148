import React from 'react';
import './peer.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as appPropTypes from '../../../appPropTypes';
import { withRoomContext } from '../../../../RoomContext';
import * as stateActions from '../../../Redux/stateActions';
import PeerView from '../PeerView/PeerView';
import pinIcon from '../../../../assets/icons/Pin.svg';
import unpinIcon from '../../../../assets/icons/Unpin.svg';

const Peer = (props) =>
{
	const {
		roomClient,
		peer,
		audioConsumer,
		videoConsumer,
		audioMuted,
		faceDetection,
		onSetStatsPeerId,
		activeSpeakerId,
	} = props;

	// console.log(roomClient);

	const audioEnabled = (
		Boolean(audioConsumer) &&
		!audioConsumer.locallyPaused &&
		!audioConsumer.remotelyPaused
	);

	const videoVisible = (
		Boolean(videoConsumer) &&
		!videoConsumer.locallyPaused &&
		!videoConsumer.remotelyPaused
	);

	return (
		<div className='Peer'>
			<div style={{width : '100%' , height : '30px', backgroundColor: '#000', bottom : '0' , top: 'unset', right: 'unset', display : 'flex'}} className='indicators'>
				<p style={{color : '#fff'}} >
					{peer.displayName}
				</p>
				
				<div style={{display : 'flex'}}>
					{!audioEnabled &&<div className='icon mic-off' />}
					{audioEnabled && <div className='icon mic-on' />}
					{!videoConsumer.length && <div className='icon webcam-off' />}
					{videoConsumer.length && <div className='icon webcam-on' />}
					{/* <If condition={!audioEnabled}>
						<div className='icon mic-off' />
					</If>
					<If condition={audioEnabled}>
						<div className='icon mic-on' />
					</If>
					<If condition={!videoConsumer}>
						<div className='icon webcam-off' />
					</If>
					<If condition={videoConsumer}>
						<div className='icon webcam-on' />
					</If> */}
				</div>
				{
				peer.id === activeSpeakerId &&	<div className="wavelength">
				<div className="boxContainer">
				  <div className="box box7"></div>
				  <div className="box box2"></div>
				  <div className="box box3"></div>
				  <div className="box box4"></div>
				  <div className="box box6"></div>			
			</div> 
			</div>
			}
			</div>
			
			{videoConsumer.map(video=>{
				return <div className='peerViewContainer' key={video.id}>
					
				<div className='upperRightCorner'>
				<div className='pinButton' onClick={() => {
					console.log('pin this user from consumer',video.id,props.pinId);
					props.setPinId(video.id);
				}}>
					{props.pinId===video.id ? <img className='pinIcon' src={unpinIcon} title='Unpin' alt="unPin this user" /> : <img className='pinIcon' src={pinIcon} title='Pin' alt="pin this user" />}
				</div>
			</div>

			<PeerView
				peer={peer}
				audioConsumerId={audioConsumer ? audioConsumer.id : null}
				videoConsumerId={video ? video.id : null}
				audioRtpParameters={audioConsumer ? audioConsumer.rtpParameters : null}
				videoRtpParameters={video ? video.rtpParameters : null}
				consumerSpatialLayers={video ? video.spatialLayers : null}
				consumerTemporalLayers={video ? video.temporalLayers : null}
				consumerCurrentSpatialLayer={
					video ? video.currentSpatialLayer : null
				}
				consumerCurrentTemporalLayer={
					video ? video.currentTemporalLayer : null
				}
				consumerPreferredSpatialLayer={
					video ? video.preferredSpatialLayer : null
				}
				consumerPreferredTemporalLayer={
					video ? video.preferredTemporalLayer : null
				}
				consumerPriority={video ? video.priority : null}
				audioTrack={audioConsumer ? audioConsumer.track : null}
				videoTrack={video ? video.track : null}
				audioMuted={audioMuted}
				videoVisible={videoVisible}
				videoMultiLayer={video && video.type !== 'simple'}
				audioCodec={audioConsumer ? audioConsumer.codec : null}
				videoCodec={video ? video.codec : null}
				audioScore={audioConsumer ? audioConsumer.score : null}
				videoScore={video ? video.score : null}
				faceDetection={faceDetection}
				onChangeVideoPreferredLayers={(spatialLayer, temporalLayer) =>
				{
					roomClient.setConsumerPreferredLayers(
						video.id, spatialLayer, temporalLayer);
				}}
				onChangeVideoPriority={(priority) =>
				{
					roomClient.setConsumerPriority(video.id, priority);
				}}
				onRequestKeyFrame={() =>
				{
					roomClient.requestConsumerKeyFrame(video.id);
				}}
				onStatsClick={onSetStatsPeerId}
			/>
			
				</div>
			})}
			
		</div>
	);
};

Peer.propTypes =
{
	roomClient       : PropTypes.any.isRequired,
	peer             : appPropTypes.Peer.isRequired,
	audioConsumer    : appPropTypes.Consumer,
	// videoConsumer    : appPropTypes.Consumer,
	audioMuted       : PropTypes.bool,
	faceDetection    : PropTypes.bool.isRequired,
	onSetStatsPeerId : PropTypes.func.isRequired
};

const mapStateToProps = (state, { id ,pinned}) =>
{
	const me = state.me;
	const pinId=state.room.pinId;
	const peer = state.peers[id];
	// const activeSpeakerId = state.room.activeSpeakerId;
	const consumersArray = peer.consumers.map((consumerId) => state.consumers[consumerId]);
	const audioConsumer =consumersArray.find((consumer) => consumer.track.kind === 'audio');
	let videoConsumer =consumersArray.filter((consumer) => consumer.track.kind === 'video');
	if(pinned){
		videoConsumer=consumersArray.filter((consumer)=>consumer.id===pinId);
	}else if(!videoConsumer.length){
		// videoConsumer=[{id:peer.id}];
		videoConsumer=[];
	}

	return {
		peer,
		audioConsumer,
		videoConsumer,
		audioMuted    : me.audioMuted,
		faceDetection : state.room.faceDetection,
		activeSpeakerId : state.room.activeSpeakerId,
		pinId,
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		onSetStatsPeerId : (peerId) => dispatch(stateActions.setRoomStatsPeerId(peerId)),
		setPinId:(id)=>{dispatch(stateActions.setPinId(id))},
	};
};

const PeerContainer = withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps
)(Peer));

export default PeerContainer;
