import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './notifications.scss';
import * as appPropTypes from '../../appPropTypes';
import * as stateActions from '../../Redux/stateActions';
import { withRoomContext } from '../../../RoomContext';
import closeButton from '../../../assets/icons/Cross-icon1.svg';
import tickButton from '../../../assets/icons/Tick.svg';
import crossButton from '../../../assets/icons/cross-red.svg';
// import { Appear } from './transitions';

const Notifications = (props) => {
	return (
		<div className='Notifications' style={props.shiftNotification ? {right : '25%', padding : '230px 0' } : {right : 0 ,  padding : '230px 0'}}> 
			{
				props.notifications.map((notification) => {
					return (
							<div key={notification.id}
								className={classnames('notification', notification.type)}>
								{/* <If condition={notification.type == 'info' || notification.type == 'error'}>
									<img onClick={() => props.onClick(notification.id)} style={{position : 'absolute' , top : '5px', right : '5px', cursor: 'pointer'}} height={'13px'} width={'13px'} src="../../resources/icons/options/Cross-icon1.svg" />
								</If> */}
								{(notification.type == 'info' || notification.type == 'error')&&<img onClick={() => props.onClick(notification.id)} style={{position : 'absolute' , top : '5px', right : '5px', cursor: 'pointer'}} height={'13px'} width={'13px'} src={closeButton} />}
								<div className='icon'>
									<img className='image-container'></img> 
								</div>

								<div className='body'>
									{/* <If condition={notification.title}>
										<p className='title'>{notification.title}</p>
									</If>  */}
									{notification.title && <p className='title'>{notification.title}</p>}
									<p className='text' style={notification.type == 'unmuteMic' || notification.type == 'muteMic' || notification.type == "enableWebcam" || notification.type == 'disableWebcam' ? {backgroundColor : 'transparent', boxShadow : 'unset'} : {backgroundColor : 'white'} } >{notification.text}</p>
									<div style={{display : 'flex'}}>
									{notification.type == "unmuteMic" && <button className='accept'
										onClick={() => props.roomClient.unmuteMic()
										}> <img height={'20px'} width={'20px'} src={tickButton}></img> Accept </button>}
									{notification.type == "muteMic" && <button className='accept'
										onClick={() => props.roomClient.muteMic()
										}> <img height={'20px'} width={'20px'} src={tickButton}></img> Accept </button>}
									{notification.type == "enableWebcam" && <button className='accept'
										onClick={() => props.roomClient.enableWebcam()
										}> <img height={'20px'} width={'20px'} src={tickButton}></img> Accept </button>}
									{notification.type == "disableWebcam" && <button className='accept'
										onClick={() => props.roomClient.disableWebcam()
										}> <img height={'20px'} width={'20px'} src={tickButton}></img> Accept </button>}
										{/* <If condition={!(notification.type == 'info' || notification.type == 'error')}>
											<button className='cancel'
											onClick={() => props.onClick(notification.id)
											}> <img height={'20px'} width={'20px'} src="../../resources/icons/options/cross-red.svg"></img> Cancel </button>
										</If> */}
										{!(notification.type == 'info' || notification.type == 'error')&&<button className='cancel'
											onClick={() => props.onClick(notification.id)
											}> <img height={'20px'} width={'20px'} src={crossButton}></img> Cancel </button>}
									</div>
								</div>
							</div>
					);
				})
			}
		</div>
	);
};

Notifications.propTypes =
{
	notifications: PropTypes.arrayOf(appPropTypes.Notification).isRequired,
	onClick: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	const { notifications } = state;

	return { notifications };
};

const mapDispatchToProps = (dispatch) => {
	return {
		onClick: (notificationId) => {
			dispatch(stateActions.removeNotification(notificationId));
		}
	};
};

const NotificationsContainer = withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps
)(Notifications));

export default NotificationsContainer;
