import property from './property-settings';
export function getProtooUrl({ roomId, peerId }) 
{
	const socketUrl = property.socketUrl;
	console.log(property.serviceUrl,"serviceUrl",property.socketUrl,'socketUrl');
	return `${socketUrl}/?roomId=${roomId}&peerId=${peerId}`;
}

export const serviceUrl=property.serviceUrl;
export const recordingAllowed=property.recordingAllowed;
export const whiteBoardAllowed=property.whiteBoardAllowed;