import { useState, useEffect, useMemo } from "react";
import axios from 'axios';
import { serviceUrl } from '../../urlFactory';


// const axiosInstance = axios.create({
// 	baseURL: serviceUrl,
// });

axios.defaults.baseURL=serviceUrl;

const useFetchapi = (method , url ,param, props, sendRequest) => {
    
    // const {meetingId}=body;
  
    const [isLoading , setIsLoading] = useState(false);
    const [apiData , setApiData] = useState(null);
    const [serverError , setServerError] = useState(null);
    // console.log(url,method);
    // const [bodyObject,setBodyObject]=useState(body);
    // const [paramsObject,setParamsObject]=useState(body);
    // const memoBody=useMemo(()=>{return body},[body.meetingId])
    useEffect( () => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const resp =await axios({
                    method : method,
                    url : serviceUrl + url,
                    params : param,
                    data : props.body,
                })

                // axios[method](url,null,JSON.parse(body)).then(res=>setApiData(res.data));
                const data = resp?.data;
                setApiData(data);
                setIsLoading(false);
            } catch(error) {
                setServerError(error);
                setIsLoading(false)
            }
        };
        if(sendRequest){
            fetchData();
        }
        // console.log(method , url , body, isLoading , apiData , serverError)
    } , [url , method ,props.body]);
   
    return {
        isLoading , apiData , serverError
    }
};

export default useFetchapi;