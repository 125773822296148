/* eslint-disable brace-style */
/* eslint-disable no-invalid-this */
/* eslint-disable no-console */
import React, { useEffect, useState, useRef } from "react";
import './peers.scss';
import { connect } from "react-redux";
import PropTypes, { element } from "prop-types";
import classnames from "classnames";
// import * as cookiesManager from "../cookiesManager";
import * as appPropTypes from "../../../appPropTypes";
// import { Appear } from "./transitions";
import Peer from "../Peer/Peer";
import Stats from "../../Stats/Stats";
// import ChatInput from "./ChatInput";
// import { isDesktop } from "../utils";
import { withRoomContext } from "../../../../RoomContext";
import property from "../../../../property-settings";
import BackgroundImage from '../../../../assets/BG-img.jpg';
import PinScreen from "../PinScreen/PinScreen";

const Peers = ({
  roomClient,
  peers,
  me,
  activeSpeakerId,
  sideOverlayId,
  connected,
  audioProducer,
  videoProducer,
  meetingControl,
  settimeformat,
  children,
  pinnedId
}) => {
  console.log(roomClient,pinnedId);
  const [openSideOverlay, setOpenSideOverlay] = useState(true);
  // const [ webcamButtonState,setWebcamButtonState ]=useState(false);

  // console.log(roomClient,peers, audioProducer, videoProducer, me, connected,meetingControl);
  let isPersenterPresent = Object.values(peers).find(
    (element) => element.id == meetingControl.presenterId
  );
  // console.log(Object.values(peers),isPersenterPresent,'check for the presenter in meeting');

  // let micState;
  // const [ currentMicStatus, setCurrentMicStatus ] = useState('');
  // let webcamState;
  // let changeWebcamState;
  // let shareState;
  // const videoVisible = Boolean(videoProducer) && !videoProducer.paused;

  // if (!me.canSendMic) {
  // 	micState = 'unsupported';
  // } else if (!audioProducer) {
  // 	micState = 'unsupported';
  // } else if (!audioProducer.paused) {
  // 	micState = 'on';
  // } else {
  // 	micState = 'off';
  // }

  // if (!me.canSendWebcam) {
  // 	webcamState = 'unsupported';
  // } else if (videoProducer && videoProducer.type !== 'share') {
  // 	webcamState = 'on';
  // } else {
  // 	webcamState = 'off';
  // }

  // if (Boolean(videoProducer) && videoProducer.type !== 'share' && me.canChangeWebCam) {
  // 	changeWebcamState = 'on';
  // } else {
  // 	changeWebcamState = 'off';
  // }

  // if (Boolean(videoProducer) && videoProducer.type === 'share') {
  // 	shareState = 'on';
  // } else {
  // 	shareState = 'off';
  // }
  // console.log(peers, peers.length, window.innerWidth);
  let singleWidth;
  let singlHeight;
  let dummyArray = [];
  let length = 7;
  // console.log(peers);

  for (let index = 0; index < length; index++) {
    dummyArray.push("dummy" + index);
  }

  if (peers.length == 1) {
    singleWidth = 78 + "%";
    singlHeight = 88 + "vh";
  } else if (peers.length == 2) {
    singleWidth = 45 + "%";
    singlHeight = 80 + "vh";
  } else if (peers.length > 2 && peers.length <= 4) {
    if (openSideOverlay) {
      singleWidth =
        (window.innerWidth - (window.innerWidth / 100) * 30) /
        (Math.sqrt(4) + 0.5) +
        "px";
      singlHeight = window.innerHeight / (Math.sqrt(4) + 0.5) + "px";
    } else {
      singleWidth = window.innerWidth / (Math.sqrt(4) + 0.5) + "px";
      singlHeight = window.innerHeight / (Math.sqrt(4) + 0.5) + "px";
    }
  } else if (peers.length > 4 && peers.length <= 9) {
    if (openSideOverlay) {
      singleWidth =
        (window.innerWidth - (window.innerWidth / 100) * 30) /
        (Math.sqrt(9) + 0.5) +
        "px";
      singlHeight = window.innerHeight / (Math.sqrt(9) + 0.5) + "px";
    } else {
      singleWidth = window.innerWidth / (Math.sqrt(9) + 0.5) + "px";
      singlHeight = window.innerHeight / (Math.sqrt(9) + 0.5) + "px";
    }
  } else if (peers.length > 9 && peers.length <= 16) {
    if (openSideOverlay) {
      singleWidth =
        (window.innerWidth - (window.innerWidth / 100) * 30) /
        (Math.sqrt(16) + 1) +
        "px";
      singlHeight = window.innerHeight / (Math.sqrt(16) + 1) + "px";
    } else {
      singleWidth = window.innerWidth / (Math.sqrt(16) + 1) + "px";
      singlHeight = window.innerHeight / (Math.sqrt(16) + 1) + "px";
    }
  } else if (peers.length > 16 && peers.length <= 25) {
    if (openSideOverlay) {
      singleWidth =
        (window.innerWidth - (window.innerWidth / 100) * 30) /
        (Math.sqrt(25) + 1) +
        "px";
      singlHeight = window.innerHeight / (Math.sqrt(25) + 1) + "px";
    } else {
      singleWidth = window.innerWidth / (Math.sqrt(25) + 1) + "px";
      singlHeight = window.innerHeight / (Math.sqrt(25) + 1) + "px";
    }
  } else if (peers.length > 25 && peers.length <= 36) {
    if (openSideOverlay) {
      singleWidth =
        (window.innerWidth - (window.innerWidth / 100) * 30) /
        (Math.sqrt(36) + 1) +
        "px";
      singlHeight = window.innerHeight / (Math.sqrt(36) + 1) + "px";
    } else {
      singleWidth = window.innerWidth / (Math.sqrt(36) + 1) + "px";
      singlHeight = window.innerHeight / (Math.sqrt(36) + 1) + "px";
    }
  } else if (peers.length > 36 && peers.length <= 101) {
    if (openSideOverlay) {
      singleWidth =
        (window.innerWidth - (window.innerWidth / 100) * 30) /
        (Math.sqrt(49) + 1) +
        "px";
      singlHeight = window.innerHeight / (Math.sqrt(49) + 1) + "px";
    } else {
      singleWidth = window.innerWidth / (Math.sqrt(49) + 1) + "px";
      singlHeight = window.innerHeight / (Math.sqrt(49) + 1) + "px";
    }
  }

  useEffect(() => {
    // setOpenSideOverlay(sideOverlayId?true:false);
    sideOverlayId ? setOpenSideOverlay(true) : setOpenSideOverlay(false);
    // this.forceUpdate();
  }, [sideOverlayId, openSideOverlay]);

  return (
    // view layout is here DON'T TOUNCH THIS Code until you understands all the checks below :-  @Aditya
    <>
      <div
        className="Peers"
        id="peers-container"
        style={
          openSideOverlay
            ? {
              width: "75%",
              height:'100%',
              maxHeight: "calc(100% - 53px)",
              overflow: "auto",
              display: "webkit-box",
              WebkitBoxPack: 'center',
              margin: 0,
              position: "relative",
            }
            : {
              width: "100%",
              height:'100%',
              maxHeight: "calc(100% - 59px)",
              overflow: "auto",
              display: "-webkit-box",
              WebkitBoxPack: 'center',
              margin: 0,
              position: "relative",
            }
        }
      >
        {
        pinnedId ?
          <PinScreen pinnedId={pinnedId}></PinScreen>
          : <React.Fragment>
            {property.view ? (
              // if view=1 then every participant is visible to the other participant
              <>
                {peers.length < 1 && (
                  <img
                    src={BackgroundImage}
                    style={{
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      height: "99vh",
                      width: "100vw",
                    }}
                  />
                )}

                {peers.map((peer) => {
                  return (
                      <div key={peer.id}
                        style={{
                          width: singleWidth,
                          height: singlHeight,
                          minHeight: "163px",
                          minWidth: "217px",
                          boxShadow: "unset",
                          margin: "3px",
                        }
                        }
                        className={classnames("peer-container", {
                          "active-speaker":
                            peer.id === activeSpeakerId && peers.length < 5,
                          "nonactive-speaker":
                            peer.id !== activeSpeakerId && peers.length < 5,
                          "active-speaker-border":
                            peer.id === activeSpeakerId && peers.length >= 5,
                          "nonactive-speaker-border":
                            peer.id !== activeSpeakerId && peers.length > 5,
                        })}
                      >
                        <Peer id={peer.id} />
                      </div>
                  );
                })}
              </>
            ) : (
              // if view =0 then participant can view only persenter which is shown by code below in betwwen <></> on basis of ther multiple checks
              <>
                {me.id == meetingControl.presenterId ? (
                  <>
                    {peers.length < 1 && (
                      <img
                        src={BackgroundImage}
                        style={{
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          height: "99vh",
                          width: "100vw",
                        }}
                      />
                    )}

                    {peers.map((peer) => {
                      return (
                        <div
                          key={peer.id}
                          style={{
                            width: singleWidth,
                            height: singlHeight,
                            minHeight: "163px",
                            minWidth: "217px",
                            boxShadow: "unset",
                            margin: "3px",
                          }
                          }
                          className={classnames("peer-container", {
                            "active-speaker":
                              peer.id === activeSpeakerId && peers.length < 5,
                            "nonactive-speaker":
                              peer.id !== activeSpeakerId && peers.length < 5,
                            "active-speaker-border":
                              peer.id === activeSpeakerId && peers.length >= 5,
                            "nonactive-speaker-border":
                              peer.id !== activeSpeakerId && peers.length > 5,
                          })}
                        >
                          <Peer id={peer.id} />
                        </div>
                      );
                    })}
                  </>
                ) : (isPersenterPresent ? (
                  me.id != meetingControl.presenterId && (

                    <div
                      key={meetingControl.presenterId}
                      style={{
                        width: "68%",
                        maxWidth: "68%",
                        height: "77vh",
                        borderRadius: "8px 8px 0 0",
                        boxShadow: "unset",
                      }
                      }
                    // className={classnames("peer-container", {
                    //   "active-speaker":
                    //     meetingControl.presenterId === activeSpeakerId,
                    //   "nonactive-speaker":
                    //     meetingControl.presenterId !== activeSpeakerId,
                    // })}
                    >
                      <Peer id={meetingControl.presenterId} />
                    </div>

                  )
                ) : (
                  <img
                    src={BackgroundImage}
                    style={{
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      height: "99vh",
                      width: "100vw",
                    }}
                  />
                ))}
              </>
            )}

            {/* <If condition={connected}> 
        <div className='controls'>
          <div
            className={classnames('button', 'mic', micState)}
            onClick={() => {
              if (micState === 'on') {
                roomClient.muteMic();
                setCurrentMicStatus('off');
              } else {
                roomClient.unmuteMic();
                setCurrentMicStatus('on');
              }
              
            }}
          />

          <div
            className={classnames('button', 'webcam', webcamState, {
              disabled: me.webcamInProgress || me.shareInProgress
            })}
            onClick={() => {
              if (webcamState === 'on') {
                cookiesManager.setDevices({ webcamEnabled: false });
                // setWebcamButtonState(false);
                roomClient.disableWebcam();
              }
              else {
                cookiesManager.setDevices({ webcamEnabled: true });
                // setWebcamButtonState(true);
                roomClient.enableWebcam();
              }
            }}
          />

          <div
            className={classnames('button', 'change-webcam', changeWebcamState, {
              disabled: me.webcamInProgress || me.shareInProgress
            })}
            onClick={() => roomClient.changeWebcam()}
          />

          <div
            className={classnames('button', 'share', shareState, {
              disabled: me.shareInProgress || me.webcamInProgress
            })}
            onClick={() => {
              if (shareState === 'on')
                roomClient.disableShare();
              else
                roomClient.enableShare();
            }}
          />
        </div>
      </If> */}
          </React.Fragment>}
        <div
          style={{
            width: "100%",
            position:
              peers.length > 24 && me.id == meetingControl.presenterId
                ? "sticky"
                : "absolute",
            bottom: "0",
            right: "20px",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          {children}
        </div>
      </div>
      <Stats timeformat={settimeformat} />
      {/* <div style={{display : 'none'}} className='chat-input-container'>
				<ChatInput />
			</div> */}
    </>
    // 	<div data-component='Peers'>
    // 	{
    // 		dummyArray.map((peer) =>
    // 		{
    // 			return (
    // 				<Appear  duration={1000}>
    // 					<div style={{ width: singleWidth ,height:singlHeight}}
    // 						className={classnames('peer-container')}
    // 					>

    // 					</div>
    // 				</Appear>
    // 			);
    // 		})
    // 	}
    // </div>
  );
};

Peers.propTypes = {
  roomClient: PropTypes.any.isRequired,
  peers: PropTypes.arrayOf(appPropTypes.Peer).isRequired,
  me: appPropTypes.Me,
  activeSpeakerId: PropTypes.string,
  sideOverlayId: PropTypes.string,
  connected: PropTypes.bool.isRequired,
  audioProducer: appPropTypes.Producer,
  videoProducer: appPropTypes.Producer,
};

const mapStateToProps = (state) => {
  const pinnedId = state.room.pinId;
  const producersArray = Object.values(state.producers);
  const peersArray = Object.values(state.peers);
  const withoutBotpeerArray=peersArray.filter(peer=>peer.id!=='bot-recorder');
  const audioProducer = producersArray.find(
    (producer) => producer.track.kind === "audio"
  );
  const videoProducer = producersArray.find(
    (producer) => producer.track.kind === "video"
  );
  const { room, me } = state;
  const { statsPeerId } = room;
  const meetingControl = state.MeetingControl;
  if (!statsPeerId)
    return {
      connected: state.room.state === "connected",
      me: me,
      meetingControl: meetingControl,
      sideOverlayId: null,
      peers: withoutBotpeerArray,
      activeSpeakerId: state.room.activeSpeakerId,
      audioProducer: audioProducer,
      videoProducer: videoProducer,
      pinnedId,
    };
  const isMe = statsPeerId === me?.id;
  const peer = isMe ? me : state?.peers[statsPeerId];

  return {
    connected: state.room.state === "connected",
    me: me,
    meetingControl: meetingControl,
    sideOverlayId: peer.id,
    peers: withoutBotpeerArray,
    activeSpeakerId: state.room.activeSpeakerId,
    audioProducer: audioProducer,
    videoProducer: videoProducer,
    pinnedId
  };
};

const PeersContainer = withRoomContext(
  connect(mapStateToProps, null, null, {
    areStatesEqual: (next, prev) => {
      return (
        prev.peers === next.peers &&
        prev.room === next.room &&
        prev.producers === next.producers
      );
    },
  })(Peers)
);

export default PeersContainer;
