import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import './participantList.scss';
import searchImage from '../../../../assets/icons/Search-icon.svg';
import ParticipantListIconTrayContainer from './ParticipantListIconTray/ParticipantListIconTray';
import PersonIcon from '@material-ui/icons/Person';

const ParticipantList=(props)=>{
    // console.log(props.peers);
    const [peersArray,setPeersArray]=useState(props.peers);
    const [searchString,setSearchString]=useState('');

    // console.log(peersArray,props.peers);

    useEffect(()=>{
        searchInPeersArray(searchString);
        props.parentCallback(props.peers.length,props.meetingControl.handRaisedBy.length);
    },[props.peers,props.meetingControl.handRaisedBy]);

    const searchInPeersArray=(stringToSearch)=>{
        // console.log(stringToSearch);
        setSearchString(stringToSearch);
        const resultArray=props.peers.filter(peer=>peer.displayName.toLowerCase().includes(stringToSearch));
        setPeersArray(resultArray );
    }
    
    return (
        <>
        <div style={{height:"100%",width:'100%' , maxHeight : '100%'}}>
         <div style={{width : '100%' , display: 'flex', position : 'relative'}}>
            <input placeholder="Search Participant" style={{marginBottom : '14px' , fontWeight : 600 , width : '100%' , border : 0, height : '38px', outline : 0, fontSize : '14px' , padding: '6px', backgroundColor : '#E9EAEC' , transition: '#E9EAEC 0.2s' , paddingLeft : '12px', borderRadius : '8px'}} type="text" value={searchString} onChange={(event)=>{searchInPeersArray(event.target.value)}}></input>
            <img style={{ cursor : 'pointer' ,position: 'absolute' , right: '10px' , top : '4px' , height: '28px' , width: '28px'}} alt='search' src={searchImage} />
        </div>
        {/* {props.me.displayName.toLowerCase().includes(searchString) && <div style={{padding: '0 12px' , display : 'flex' , alignItems: 'center', justifyContent : 'space-between', boxShadow : '0 3px 6px rgba(0, 0, 0 , 0.16)', borderRadius : '6px' , height : '36px' , marginBottom : '12px'}} >
                        <div style={{fontWeight: 600 , color : 'rgba(59,56,56,1)', display: 'flex' , fontSize : '14px'}}> {props.me.displayName} </div></div>} */}
        
        {            
            peersArray.map(peer=>{
                return(
                    <div  key={peer.id} style={{padding: '0 12px' , display : 'flex' , alignItems: 'center', justifyContent : 'space-between', boxShadow : '0 3px 6px rgba(0, 0, 0 , 0.16)', borderRadius : '6px' , height : '36px' , marginBottom : '12px'}} key={peer.id} id={peer.id}>
                        <div title={props.meetingControl.presenterId===peer.id?'Presenter/Host':'Participant'} style={{fontWeight: 600 , color : 'rgba(59,56,56,1)', display: 'flex' , alignItems:'center', fontSize : '14px'}}> {peer.displayName}
                            {props.meetingControl.presenterId===peer.id && <PersonIcon title="Presenter/Host" style={{color:'#0197D6'}} />}
                            {peer.id === props.activeSpeaker && 
                                <div className="wavelength1">
                                    <div className="boxContainer">
                                        <div className="box box7"></div>
                                        <div className="box box2"></div>
                                        <div className="box box3"></div>
                                        <div className="box box4"></div>
                                        <div className="box box6"></div>
                                    </div>
                                </div>
                            }
                         </div>
                        {/* {peer.id==props.meetingControl.presenterId && <p>Presenter </p>} */}
                        <div style={props.meetingControl.presenterId === props.me.id ? {pointerEvents : 'auto'} : {pointerEvents : 'none'}}>
                            {peer.displayName!==props.me.displayName && <ParticipantListIconTrayContainer id={peer.id} />}
                        </div>
                    </div>
                );
            })}
            </div>
        </>
    ); 
}


const mapStateToProps=(state,ownProps)=>{
    const peersArray=Object.values(state.peers);
    const me =state.me;
    const updatedArray=[{displayName:me.displayName,id:me.id},...peersArray];
    const withoutBotUpdatedArray=updatedArray.filter(peer=>peer.id!=='bot-recorder');
    const meetingControl=state.MeetingControl;
    return {
        peers:withoutBotUpdatedArray,
        me:me,
        meetingControl:meetingControl,
        activeSpeaker : state.room.activeSpeakerId
    }
}

const mapDispatchToProps=(dispatch)=>{}

const ParticipantListContainer=connect(
    mapStateToProps,
    null,
    null,
    {
		areStatesEqual: (next, prev) => {
			return (
				prev.peers === next.peers&&
                prev.room.activeSpeakerId===next.room.activeSpeakerId
            );
		}
	}
    )(ParticipantList);

export default ParticipantListContainer;