import Hark from "hark";
import { useState, useEffect } from "react";
import { serviceUrl } from '../../urlFactory';


const initialState = {
    recordingMinutes : 0,
    recordingSeconds : 0,
    initRecording : false,
    mediaStream : null,
    mediaRecorder : null,
    audio : null,
    blob : null
}
 
export default function useRecorder(){
    const [recorderState , setRecorderState] = useState(initialState);
    let [hark,setHark]=useState('');
    useEffect(() => {
        // console.log(recorderState, 'recording state')
    })
    useEffect(() => {
        const MAX_RECORDER_TIME = 100;
        let recordingInterval = null;
        if(recorderState.initRecording){
            recordingInterval = setInterval(() => {
                setRecorderState((prevState) => {
                    if(prevState.recordingMinutes === MAX_RECORDER_TIME && prevState.recordingSeconds === 0){
                        clearInterval(recordingInterval)
                        return prevState
                    }
                    if(prevState.recordingSeconds >= 0 && prevState.recordingSeconds < 59){
                        return {
                            ...prevState,
                            recordingSeconds : prevState.recordingSeconds + 1,
                        }
                    }
                    if(prevState.recordingSeconds === 59){
                        return {
                            ...prevState,
                            recordingMinutes : prevState.recordingMinutes + 1,
                            recordingSeconds : 0
                        }
                    }
                })
            } , 1000)
        }
        else{
            clearInterval(recordingInterval);
        } 
        
        return () => clearInterval(recordingInterval);
    });
    
    useEffect(() => {
        if(recorderState.mediaStream){  
            setRecorderState((prevState) => {
                return {
                    ...prevState,
                    mediaRecorder : new MediaRecorder(recorderState.mediaStream)
                };
            })
        }
    } , [recorderState.mediaStream])

    useEffect(() => {
        const recorder = recorderState.mediaRecorder;
        let chunks = [];
        if(recorder && recorder.state === 'inactive'){
            recorder.start();
            recorder.ondataavailable = (e) => {
                chunks.push(e.data);
            }

            recorder.onstop = () => {
                const blob = new Blob(chunks , {type: "audio/gg; codecs=opus"});
                chunks = [];
                setRecorderState((prevState) => {
                    if(prevState.mediaRecorder){
                        return {
                            ...initialState, 
                            audio : URL.createObjectURL(blob),
                            blob : blob
                        };
                    }else{
                        return initialState;
                    }
                })
            }
        }
        return () => {
            if(recorder) recorder.stream.getAudioTracks().forEach(track => {
                track.stop();
            });
        }
    } , [recorderState.mediaRecorder])

    async function startRecording(setRecorderState){
        try{
            const stream = await navigator.mediaDevices.getUserMedia({audio : true});
            console.log(stream.getAudioTracks());
            let harkInstance=Hark(stream, { play: false });
            setHark(harkInstance);
            harkInstance.on('volume_change', (dBs, threshold) => {
                    // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
                    //   Math.pow(10, dBs / 20)
                    // However it does not produce a visually useful output, so let exagerate
                    // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
                    // minimize component renderings.
                    let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);
                    // console.log(audioVolume);
                    // if (audioVolume === 1)
                    //     audioVolume = 0;

                    // if (audioVolume !== audioVolume)
                    //     // this.setState({ audioVolume });
                    //     console.log(audioVolume);
            });
            setRecorderState((prevState) => {
                return {
                    ...prevState,
                    initRecording : true,
                    mediaStream : stream,
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    async function saveRecording(recorder){
        if(recorder.state !== "inactive"){
            await recorderState.mediaStream.getTracks()[0].stop()
            setRecorderState({...recorderState,mediaStream:null});
            recorder.stop();
            hark.stop();
        }

    }

    return{
        recorderState,
        startRecording : () => startRecording(setRecorderState),
        cancelRecording : () => {
            hark.stop();
            setRecorderState(initialState)},
        saveRecording : () => saveRecording(recorderState.mediaRecorder)
    }   
}