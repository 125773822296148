import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './joiningComponent.scss';
// import Peers from './Peers';
// import * as CookiesManager from '../cookiesManager';
import * as stateActions from '../../Redux/stateActions';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import classnames from 'classnames';
import logo from '../../../assets/icons/Logo-LSMeet.svg';


const JoiningComponent = (props) => {
	console.log(props.meetingType);
	console.log(props.meetingType)
	document.title = props.meetingTitle
	// const welcomeMessage = "LSMeet by LIBSYS Ltd. is a Video-enabled learning platform that drive better outcomes for Educational Institutions & Corporates. It Provides a platform to extend the classroom for online students attending from a remote location. LIBSYS Ltd. has been developing software products for over 3 decades. We offer a wide range of solutions that include ‘symphonyX’ suite of SaaS offerings for Classroom Learning, Student Lifecycle and Academic Campus automation, ‘TargetX’ Online AdmissionPlatform, ‘LIBSYS’ LMS , ‘LSmart’ RFID Solutions,‘LSDiscovery’ One Search Engine and ‘LSNetX’ OmniChannel Retailing Solution, for institutes and business enterprises. Our passion for innovation and quality guides us to incorporate latest technologies and global standards in all our applications.";
	const msg1 = 'Connect, meet and collaborate with LSMeet video conferencing software.'
	const msg2 = 'LSMeet, a complete communications solution for businesses offering secure and easy to use integrated video calling without the need of separate apps.'
	const msg3 = 'Schedule a meeting in seconds and invite participants. Join scheduled and ad-hoc meetings directly from your browser without installing any plugins. Enhance participation and teamwork with features like screen sharing, document sharing and chat.'
	const videoRef = useRef(null);
	let availabledevices = [];
	const initialValue = [];
	const [showJoinButton, setShowJoinButton] = useState(false);
	const [cameraarray, setCameraarray] = useState(initialValue);
	const [showhideMenu, setShowhideMenu] = useState(false);
	const [currentCameraId, setCurrentCameraId] = useState('');
	const [toggleVideo, setToggleVideo] = useState(props.initalStateOfWebcam);
	const [toggleAudio, setToggleAudio] = useState(props.intialStateOfMic);
	const [message, setMessage] = useState('');
	const [userName, setUserName] = useState('');
	const month = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

	async function fetchMediaDevices() {
		let constraints = { audio: true, video: true };
		// await navigator.mediaDevices.getUserMedia(constraints).then(stream=>{
		// 	console.log(stream);
		// });

		//@Aditya
		//enumerateDevice doesn't wait for user permission of camera and mic so you can't await for this function but getUserMedia does .
		await navigator.mediaDevices.enumerateDevices().then(devices => {
			console.log(devices, 'list of devices')
			for (let i = 0; i < devices.length; i++) {
				let device = devices[i];
				if (device.kind === 'videoinput') {
					// let option = document.createElement('option');
					// option.value = device.deviceId;
					// option.text = device.label || 'camera' + (i + 1);
					// console.log(device.label)
					// document.querySelector('select#videoSource').appendChild(option);		
					availabledevices.push({ deviceName: device.label, id: device.deviceId });
					// setCameraarray(availabledevices);	
				}
			}
		});
		return new Promise((resolve) => {
			console.log(availabledevices, 'resolving the promise');
			resolve(availabledevices);
		})
	}

	const getVideo = async (cameraId, label, isMounted) => {
		setCurrentCameraId({ cameraId, label });
		let constraints = toggleAudio ? { audio: { echoCancellation: true, noiseSuppression: true }, video: { deviceId: cameraId } } : { audio: false, video: { deviceId: cameraId } };
		await navigator.mediaDevices
			.getUserMedia(constraints)
			.then(mediaStream => {
				//Author- @Aditya
				//in case of the user permission ,///// mediaDevices.enumerateDevice doesn't care about user permission so it doesn't wait for user permission to allow or block but mediaDevices.getUserMedia wait for userPermission
				// if there is permission allowed then we set showjoinbutton otherwise we set it to false so it doesnt' show join button
				//there is dependency of useEffect on showJoinButton to update avaviable device and our stream 
				isMounted && setShowJoinButton(true);
				let video = videoRef.current;
				video.srcObject = mediaStream;
				video.onloadedmetadata = function (e) {
					video.play();
				}
			})
			.catch((err) => {
				//@Aditya
				//hide join button in case of the permission is not allowed to camera and mic 
				setShowJoinButton(false);
				console.log(err.name, 'getting error');
			})
	}

	useEffect(() => {
		let isMounted=true;  //to stop memory leak due to async call and video fetching
		fetchMediaDevices().then(data => {
			console.log(availabledevices.length,props.me,props.userId);
			//to bypass bot-recorder without checking his camera and any media device  ----------###########-----------  @aditya
			isMounted && setCurrentCameraId(availabledevices[0]?.id, availabledevices[0]?.deviceName);
			isMounted && setCameraarray(availabledevices);
			isMounted && getVideo(availabledevices[0].id, availabledevices[0].deviceName,isMounted);
		}).catch(() => { });

		return ()=>{
			isMounted=false;
		}
	}, [showJoinButton]);

	useEffect(() => {
		if (toggleVideo == true) {
			getVideo(currentCameraId);
			setMessage('');
		} else {
			setMessage('video is turned off')
		}
	}, [toggleVideo]);

	useEffect(() => {
		getVideo(currentCameraId);

	}, [toggleAudio])


	const showAndHide = () => {
		setShowhideMenu(!showhideMenu)
	}
	const handleVideo = () => {
		setToggleVideo(!toggleVideo);
	}
	console.log(availabledevices, 'outer available devices');
	return (
		<div className='joiningRoom'>
			<header>
				<img src={logo} alt="lsmeet logo" height="43px" />

			</header>
			<div className="welcome-body">
				<div className="userName" ></div>
				<section className="parent-container" >
					<div className="lhs-container">
						<div className="video-conainer" >
							{
								message.length > 0 && <p className="message">{`${message}`}</p>
							}
							{
								cameraarray.length == 0 && <p className="message">No Device attached</p>
							}
							{
								toggleVideo && <video ref={videoRef}></video>
							}
							{
								cameraarray.length > 0 && <>
									<div className="icons-container" style={{ width: '200px', height: '104px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', bottom: '16px', left: 'calc(50% - 100px)' }}>
										<div className='left-audio'>
											<div className={classnames({
												'left-image': toggleAudio,
												'left-image-disable': !toggleAudio
											})}
												onClick={() => {
													setToggleAudio(!toggleAudio);
												}}
											></div>
										</div>
										<div className='right-video'>
											<div className={classnames({
												'right-image': toggleVideo,
												'right-image-disable': !toggleVideo
											})}
												onClick={handleVideo}
											></div>
										</div>
									</div>
									{/* <div className="more-div" >
										<div className="more-icon" onClick={showAndHide} ></div>
										{
											showhideMenu &&
											<div style={{ width: '200px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 3px 6px rgb(0 0 0 / 24%)' }} >
												{cameraarray.map(device => {
													return <MenuItem style={{ backgroundColor: currentCameraId == device.id ? '#3c3c3c' : 'white', color: currentCameraId == device.id ? '#fff' : 'black', display: 'inline-block', width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={(event) => {
														getVideo(device.id,device.deviceName); showAndHide();
													}} key={device.id}>{device.deviceName}</MenuItem>
												})}
											</div>
										}
									</div> */}
								</>
							}
						</div>
					</div>
					<div className="rhs-container" >
						<p className="title">
							{`${props.userName || 'User'}`}, get ready to join	{`${props.meetingTitle}`} {`${props.meetingsateTimee}`} with these settings.
						</p>
						<hr style={{ color: 'rgba(255,255,255,0.21)', marginBottom: '12px', marginTop: '20px' }} ></hr>
						<div className="description">
							{/* <p className="descriptionPara" >{welcomeMessage}</p> */}
							<div className="descriptionPara" >
								<ul style={{ listStyleType: 'circle', listStyleType: 'disc', listStylePosition: 'outside', paddingLeft: '20px' }}>
									<li>{msg1}</li>
									<li style={{ padding: '29px 0' }}>{msg2}</li>
									<li>{msg3}</li>
								</ul>
							</div>

						</div>
						<div className={(props.meetingType === 'open' && showJoinButton && !props.userName) ? 'joinNowWithOpen' : 'joinNowWithClose'} >
							{
								(props.meetingType === 'open' && showJoinButton && !props.userName) && <> <span style={{ fontSize: '19px', color: 'white' }}>Enter Your Name to Join Now</span> </>
							}
							<div>
								{
									(props.meetingType === "open" && showJoinButton && !props.userName) && <input className='userName' placeholder='Enter Your Name' value={userName} onChange={(event) => setUserName(event.target.value.trimStart())}></input>
								}
								{
									showJoinButton && <button className="button-container" disabled={props.meetingType === "open" ? (!props.userName && userName.trim() === '') : false} onClick={() => {
										props.updateWebcamState(toggleVideo);
										props.updateMicState(toggleAudio);
										console.log(currentCameraId);
										// props.updateWebcamId([currentCameraId]); 
										props.onClickJoinButton(userName);
									}}>{props.me.id === props.presenterId ? 'Start Meeting' : 'Join Now'}</button>
								}
							</div>
						</div>
					</div>
				</section>
			</div>
			<footer>
				<p>LSMeet from LIBSYS Ltd. Copyright &copy; 2022 All Rights Reserved.</p>
			</footer>
		</div>
	);
};

const month = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const mapStateToProps = (state) => {
	return {
		meetingAuthenticated: state.MeetingControl.meetingAuthenticated,
		allowedToJoinMeeting: state.MeetingControl.mettingAllowedToJoin,
		meetingTitle: state.MeetingControl.meetingTitle,
		userName: state.me.displayName,
		meetingStartTime: state.MeetingControl.meetingStartTime,
		meetingsateTimee: new Date(state.MeetingControl.meetingStartTime).getDate() + ' ' + month[new Date(state.MeetingControl.meetingStartTime).getMonth()] + ' ' + new Date(state.MeetingControl.meetingStartTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
		intialStateOfMic: state.MeetingControl.initialStateOfMic,
		initalStateOfWebcam: state.MeetingControl.initialStateOfWebcam,
		me: state.me,
		presenterId: state.MeetingControl.presenterId,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateWebcamState: (flag) => {
			return dispatch(stateActions.setInitalStateOfWebcam(flag));
		},

		updateMicState: (flag) => {
			return dispatch(stateActions.setInitalStateOfMic(flag));
		},

		updateWebcamId: (id) => {
			return dispatch(stateActions.setSelectedWebcamId(id));
		}
	}
};

const JoiningComponentContainer = connect(mapStateToProps, mapDispatchToProps)(JoiningComponent);

export default JoiningComponentContainer;
