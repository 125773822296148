import { bindActionCreators } from "redux";

const intitalState = {
	meetingAuthenticated: false,
	mettingAllowedToJoin: false,
	meetingTitle: '',
	presenterId: '',
	serverCurrentTime: '',
	meetingStartTime: '',
	currentErrorMsg: '',
	initialStateOfMic: true,
	initialStateOfWebcam: true,
	selectedWebcamId: '',
	selectedMicId: '',
	handRaisedBy:[],
	meetingType : ''
};

const meetingControl = (state = intitalState, action) => {
	switch (action.type) {
		case 'SET_MEETING_AUTHENTICATED':
			return { ...state, meetingAuthenticated: action.payload.flag };

		// case 'SET_MEETING_JOINING_STATUS':
		// 	return { ...state, meetingAllowed: action.payload.flag };

		case 'SET_MEETING_TITLE':
			return { ...state, meetingTitle: action.payload };

		case 'SET_MEETING_START_TIME':
			return { ...state, meetingStartTime: action.payload };

		case 'SET_SERVER_CURRENT_TIME':
			return { ...state, serverCurrentTime: action.payload };

		case 'SET_ROOM_PRESENTER_ID':
			return { ...state, presenterId: action.payload };

		case 'SET_CURRENT_ERROR_MSG':
			return { ...state, currentErrorMsg: action.payload };

		case 'INTITAL_STATE_OF_MIC':
			return { ...state, initialStateOfMic: action.payload };

		case 'INTITAL_STATE_OF_WEBCAM':
			return { ...state, initialStateOfWebcam: action.payload };

		case 'SELECTED_WEBCAM_ID':
			return { ...state, selectedWebcamId: action.payload };

		case 'SELECTED_MIC_ID':
			return { ...state, selectedMicId: action.payload };

		case 'SET_ROOM_TYPE': 
			return { ...state , meetingType : action.payload };	

		case 'HAND_RAISE_ADD':{
			if(state.handRaisedBy.includes(action.payload)){
				return;
			}
			let handRaisedArray=Array.from(state.handRaisedBy);
			handRaisedArray.push(action.payload); 
			return {...state,handRaisedBy:handRaisedArray}
		}
		case 'HAND_RAISE_DELETE':{
			if(!state.handRaisedBy.includes(action.payload)){
				return;
			}
			let handRaisedArray=Array.from(state.handRaisedBy);
			let indexOffId=handRaisedArray.indexOf(action.payload);
			console.log(indexOffId);
			handRaisedArray.splice(indexOffId,1);
			return {...state,handRaisedBy:handRaisedArray};
		}	
		default:
			return state;
	}
};

export default meetingControl;
