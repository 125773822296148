import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './ParticipantListAndChatPanel.scss';
import ChatBoxContainer from './ChatBox/ChatBox';
import ParticipantListContainer from './ParticipantList/ParticipantList';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails  from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChatInput from './ChatInput/ChatInput';
import useFetchapi from '../../utils/useFetchapi';
// import { GroupWorkSharpIcon } from '@material-ui/icons/GroupWorkSharp'

 

const ParicipantChatPanel = (props) => {

    const [toggleExpensionPanel , setToggleExpensionPanel] = useState('participant');
    const [currentOpenPanel , setCurrentOpenPanel] = useState('participant');
    const [participantCount , setParticipantCount] = useState(0);
    const [showHandRaise,setShowHandRaise]=useState(false);
    const firstAccordian=useRef(null);
    const secondAccordian=useRef(null);

    // const {isLoading , apiData , serverError} = useFetchapi('GET' , '/getMeetingChat' , {'meetingId' : 'libsys'}  , {} );
	// let chatArr = [];
	// if(apiData?.chatList.length){
	// 	apiData.chatList.forEach((data, index) => {
	// 		if (chatArr.length && chatArr[chatArr.length-1]?.id===data.participantId) {
	// 			let lastMsg = chatArr[chatArr.length - 1];
	// 			lastMsg.text = [...lastMsg?.text, {msg:data.chatMessage,timeStamp:new Date(data.createTime).toLocaleTimeString('en-US',{hour12:'true',hour:'numeric',minute:'numeric'})}];
	// 			chatArr[chatArr.length - 1] = lastMsg;
	// 		} else {
	// 			chatArr.push({ id: data.participantId, title: data.participantName ? data.participantName : 'None', text: [{ msg: data.chatMessage, timeStamp: new Date(data.createTime).toLocaleTimeString('en-US',{hour12:'true',hour:'numeric',minute:'numeric'})}]});
	// 		}
	// 	})  
	// }
	// console.log(chatArr)

    const handleExpensionPanel = (value) => {
        if(value == 'participant'){
            if(currentOpenPanel == 'participant'){
                setToggleExpensionPanel('closed');
                setCurrentOpenPanel('closed')
            }else{
                setToggleExpensionPanel('participant');
                setCurrentOpenPanel('participant')
            }
        }else if(value == 'chat'){
            if(currentOpenPanel == 'chat'){
                setToggleExpensionPanel('closed'); 
                setCurrentOpenPanel('closed')
            }else{
                setToggleExpensionPanel('chat');
                setCurrentOpenPanel('chat')
            } 
        }else{
            setToggleExpensionPanel(value);
            setCurrentOpenPanel(value); 
        }
    }

    return (
        <div className='ParticipantChat'>
            <Accordion style={{ position: 'relative', boxShadow: 'unset' }} expanded={toggleExpensionPanel === 'participant'} >
                <AccordionSummary className='participant-chat-header' onClick={() => handleExpensionPanel('participant')} expandIcon={<ExpandMoreIcon />} >
                    <span className='participantIcon'></span>
                    <span className='panelName'>Participants </span>
                    <span className='participant-chat-panel-icon'>
                        <span className="totalNum">{`${participantCount}`}</span>
                        {showHandRaise ? <span className='handRaiseIcon'></span> : null}
                    </span>

                </AccordionSummary>
                <AccordionDetails style={{ height: 'calc(100vh - 156px)' }}>
                    <ParticipantListContainer parentCallback={(childData, isHandRaised) => {
                        setParticipantCount(childData);
                        setShowHandRaise(isHandRaised);
                    }} />
                </AccordionDetails>
            </Accordion>
            <Accordion style={{ position: 'relative', paddingTop: '8px', boxShadow: 'unset' }} expanded={toggleExpensionPanel === 'chat'} >
                <AccordionSummary className='participant-chat-header' onClick={() => handleExpensionPanel('chat')} expandIcon={<ExpandMoreIcon />}>
                    <span className='chatIcon'></span>  <span className='panelName'>Meeting Chat</span>
                </AccordionSummary>
                <AccordionDetails style={{ height: 'calc(100vh - 156px)', display: 'block' }}>
                    <div style={{ height: 'calc(100% - 80px)', position: 'relative' }}>
                        <ChatBoxContainer />
                    </div>
                    {
                        currentOpenPanel == 'chat' &&
                        <div className='chat-input-container'>
                            <ChatInput />
                        </div>
                    }

                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ParicipantChatPanel;