const  property = {
    "socketUrl": process.env.REACT_APP_SOCKET_URL,
    "serviceUrl": process.env.REACT_APP_SERVICE_URL,
    "view": +process.env.REACT_APP_VIEW,
    "recordingAllowed": +process.env.REACT_APP_RECORDING_ALLOWED,
    "whiteBoardAllowed": +process.env.REACT_APP_WHITE_BOARD_ALLOWED,
    "encryptedMeetingLink": +process.env.REACT_APP_ENCRYPTED_MEETING_LINK,
    "useScreenShareHDQuality": +process.env.REACT_APP_USE_SCREEN_SHARE_HD_QUALITY,
    "currentEnv": process.env.REACT_APP_currentEnv,
};

export default property;

