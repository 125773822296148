import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import UrlParse from 'url-parse';
import axios from 'axios';
import { Base64 } from 'js-base64';
import * as cryp from 'crypto-js';
import JoiningComponent from '../JoiningComponent/JoiningComponent';
import ExitComponents from '../ExitComponent/ExitComponent';
import Room from '../Room/Room';
import { getProtooUrl, serviceUrl } from '../../../urlFactory';
import * as stateActions from '../../Redux/stateActions';
import deviceInfo from '../../../deviceInfo';
import property from '../../../property-settings';
import { withRoomContext } from '../../../RoomContext';
import useFetchapi from '../../utils/useFetchapi';
import axiosUtils from '../../utils/axiosInstanse';

const axiosInstance = axios.create({
	baseURL: serviceUrl,
});

// const key="Bar12345Bar12345";
// const iv='RandomInitVector';

const MeetingController = (props) => {

	// console.log(props);
	const [roomId, setRoomId] = useState(null);
	const [peerId, setPeerId] = useState('');
	const [roomClient, setRoomClient] = useState(props.roomClient);
	const [joiningAllowedByUser, setJoiningAllowedByUser] = useState(false);
	const [testing, setTesting] = useState(true);
	const [meetingType , setMeetingType] = useState('closed');
	

	// console.log(props.meetingAuthenticated,'meetingAuthentication');

	// const {isLoading , apiData , serverError} = useFetchapi('GET' , '/getMeetingChat' , {'meetingId' : 'libsys'}  , {'meetingId' : 'libs'} )
	const urlParser = UrlParse(window.location.href, true);

	useEffect(() => {
		// const requestOptions={
		// 	method:'POST',
		// 	headers:{'content-Type':'application/json'},
		// 	body : JSON.stringify({meetingId:'libsys'})
		// };

		console.log(props.roomClient);
		console.log(urlParser, serviceUrl);
		// console.log(property.encryptedMeetingLink);
		let meetingId = "";
		let participantId = "";
		let params = '';
		let decrypted;

		// let tempKey=cryp.enc.Base64.parse("u/Gu5posvwDsXUnV5Zaq4g==");;
		// let tempiv=cryp.enc.Base64.parse("5D9r9ZVzEYYgha93/aUK2w==");
		// console.log(cryp.AES.encrypt('roomId=libsys&userId=admin', tempKey,{ mode: cryp.mode.CBC, padding: cryp.pad.Pkcs7, iv: tempiv, }).ciphertext.toString(cryp.enc.Base64url));
		// const encryp=cryp.enc.Utf8.parse(cryp.AES.encrypt('roomId=libsys&userId=admin', tempKey,{ mode: cryp.mode.CBC, padding: cryp.pad.Pkcs7, iv: tempiv, }).toString());
		// console.log(cryp.enc.Base64url.stringify(encryp),encryp,'encrypt');
		// let test=cryp.enc.Base64url.parse('5D9r9ZVzEYYgha93/aUK2w==');
		// console.log( cryp.enc.Utf8.stringify('5D9r9ZVzEYYgha93/aUK2w=='),'5D9r9ZVzEYYgha93/aUK2w==','iutoyrt');
		// console.log( cryp.enc.Base64url.(test),'anshul');


		if (property.encryptedMeetingLink) {
			try {
				let encryptedParams = Object.keys(urlParser.query)[0];
				// console.log(encryptedParams,'encryptedParams');
				// console.log(encryptedParams.length);
				var Base64EncText = cryp.enc.Base64url.parse(encryptedParams);
				var Base64EncKey = cryp.enc.Base64.parse("u/Gu5posvwDsXUnV5Zaq4g==");
				var Base64EncIv = cryp.enc.Base64.parse("5D9r9ZVzEYYgha93/aUK2w==");
				decrypted = cryp.enc.Utf8.stringify(cryp.AES.decrypt({ ciphertext: Base64EncText }, Base64EncKey, { mode: cryp.mode.CBC, padding: cryp.pad.Pkcs7, iv: Base64EncIv, }));
				console.log(decrypted);
				console.log(decrypted, 'decryption');
				// console.log(decrypted.split("&")[0].split('='));
				params = decrypted.split("&");
				// console.log(decrypted);
				meetingId = params[0].split('=')[1];
				let parti = params[1].split('=')[1];
				setRoomId(meetingId);
				setPeerId(parti);
			} catch (error) {
				console.log(error);
			}


		} else {
			meetingId = urlParser.query.roomId;
			participantId = urlParser.query.userId;
			console.log('non-encrypted meeting happening');
		}
		const meetingBody = { meetingId };
		console.log(participantId);
		axiosUtils.post('/validateMeeting', meetingBody).then(res => res.data).then(res => {
			// console.log(res);
			if (res.flag == 0) {
				props.roomIsValid(true);
				props.setRoomId(meetingId);
				props.setMeetingTitle(res.meetingTitle);
				props.setServerCurrentTime(res.currentTime);
				props.setMeetingStartTime(res.meetingStartTime);
				props.setPresenterId(res.presenterId);
				props.setMeetingTypeStatus(res.meetingType);
				setMeetingType(res.meetingType);
				console.log(new Date(res.meetingEndTime).getTime(),new Date(res.meetingStartTime).getTime())
				console.log(((((new Date(res.meetingEndTime).getTime()-new Date(res.meetingStartTime).getTime()/1000)/60)/60)/24)/30);
				let participantId = '';
				if (property.encryptedMeetingLink) {
					participantId = params[1].split('=')[1];
				} else {
					participantId = urlParser.query.userId;
				}
				let participantBody = { ...meetingBody, participantId };
				if (participantId !== 'bot-recorder') {
					if ( res.meetingType === 'closed' || participantId) {
						axiosInstance.post('/validateParticipant', participantBody).then(res => res.data).then(res => {
							if (res.flag === 0) {
								const device = deviceInfo();
								const displayNameSet = true;
								props.setMyInfo(participantId, res.participantName, displayNameSet, device);
								console.log(roomId, peerId, props.roomClient, props, roomClient);
								if (props.roomClient) {
									props.roomClient._protooUrl = getProtooUrl({ roomId, peerId });
								}
							} else {
								props.roomIsValid(false);
								props.setErrorMsg(res.message);
							}

						});
					}else if(res.meetingType==='open'){
						// for future use
					}

				} else {
					const device = deviceInfo();
					props.setMyInfo('bot-recorder', 'bot-recorder', true, device);
					setJoiningAllowedByUser(true);
				}

			} else {
				props.roomIsValid(false);
				props.setErrorMsg(res.message);
			}
		}).catch(error => {
			props.roomIsValid(false);
			props.setErrorMsg('Something Went Wrong!!! Please Try Again');
			console.log(error);
		});
	}, []);

	const onClickingJoinButton = (userName) => {
		// console.log('button is Clicked');
		if(meetingType.toLocaleLowerCase() === 'open' && (peerId.trim()==='')){
			let addParticipantBody = {  participantName : userName  , meetingId : urlParser.query.roomId || roomId }
			axiosUtils.post('/addGuestParticipant' , addParticipantBody).then(res => res.data).then(res => {
				if(res.participantId){
					const device = deviceInfo();
   					const displayNameSet = true;
					props.setMyInfo(res.participantId, res.participantName, displayNameSet, device);
					props.roomClient._peerId = res.participantId;
					props.roomClient._protooUrl = getProtooUrl({ roomId:addParticipantBody.meetingId, peerId:res.participantId });
					setJoiningAllowedByUser(true);
				}
			}).catch(error=>{
				alert(error.message);
			})
		}else{
			setJoiningAllowedByUser(true);
		}
		
	};

	const endMeeting = () => {
		const urlParser = UrlParse(window.location.href, true);
		let meetingId = '';
		let participantId = '';
		if (property.encryptedMeetingLink) {
			meetingId = roomId;
			participantId = peerId;
		} else {
			meetingId = urlParser.query.roomId;
			participantId = urlParser.query.userId;
		}

		let body = { meetingId, participantId };
		axiosInstance.post('/endMeeting', body).then(res => res.data).then(res => {
			// console.log(res);
			props.roomIsValid(false);
		});


		// props.roomIsValid(false);

		// console.log(props.roomIsClosed);
	};

	useEffect(() => {
		if (props.roomClient && Boolean(property.encryptedMeetingLink)) {
			props.roomClient._protooUrl = getProtooUrl({ roomId, peerId });
			props.roomClient._roomId = roomId;
			props.roomClient._peerId = peerId;
			props.setRoomId(roomId);
		}
		console.log(props, props.roomClient, getProtooUrl({ roomId, peerId }));
		setRoomClient(props.roomClient);

	}, [props.roomClient, roomId, peerId]);

	// console.log(props.roomIsClosed);
	return (
		<>
			{(props.meetingAuthenticated && !joiningAllowedByUser) ? <JoiningComponent meetingType={meetingType} userId={peerId} onClickJoinButton={onClickingJoinButton} currentValueOfButton={joiningAllowedByUser} testing={testing} setTesting={setTesting} /> : null}
			{(props.meetingAuthenticated && joiningAllowedByUser) && <Room endMeeting={endMeeting} />}
			{!props.meetingAuthenticated && <ExitComponents />}
			{/* <If condition={!joiningAllowedByUser && props.meetingAuthenticated}> */}
			{/* <JoiningComponent onClickJoinButton={onClickingJoinButton} currentValueOfButton={joiningAllowedByUser} testing={testing} setTesting={setTesting} /> */}
			{/* </If> */}
			{/* <If condition={joiningAllowedByUser && props.meetingAuthenticated}> */}
			{/* <Room endMeeting={endMeeting} /> */}
			{/* </If> */}
			{/* <If condition={!props.meetingAuthenticated}> */}
			{/* <ExitComponents /> */}
			{/* </If> */}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		meetingAuthenticated: state.MeetingControl.meetingAuthenticated,
		AllowedToJoinMeeting: state.MeetingControl.mettingAllowedToJoin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		roomIsValid: (flag) => {
			dispatch(stateActions.setRoomIsValid(
				{
					flag: flag
				}
			));
		},

		setRoomId: (id) => {
			dispatch(stateActions.setRoomId(id));
		},

		setMeetingTitle: (title) => {
			dispatch(stateActions.setRoomTitle(title));
		},

		setMeetingStartTime: (startTime) => {
			dispatch(stateActions.setMeetingStartTime(startTime));
		},

		setErrorMsg: (errorMsg) => {
			dispatch(stateActions.setCurrentErrorMsg(errorMsg));
		},

		setPresenterId: (presenterId) => {
			dispatch(stateActions.setRoomPresenterId(presenterId));
		},

		setMeetingTypeStatus: (meetingType) => {
			dispatch(stateActions.setRoomType(meetingType));
		},

		setServerCurrentTime: (currentTime) => {
			dispatch(stateActions.setServerCurrentTime(currentTime));
		},

		setMyInfo: (peerId, displayName, displayNameSet, device) => {
			dispatch(stateActions.setMe({ peerId, displayName, displayNameSet, device }));
		}
	}
}

const MeetingControllerContainer = withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
)(MeetingController));

export default MeetingControllerContainer;
