import axios from 'axios';
import { serviceUrl } from '../../urlFactory';

const instance=axios.create({
    baseURL:serviceUrl,
});

instance.defaults.headers.common['Authorization']='Joshi Badmash';

instance.interceptors.request.use(req=>{
    console.log(req,'catch by request interceptors');
    return req;
},error=>{
    console.log(error,'error by request interceptor');
    return Promise.reject(error);
});

instance.interceptors.response.use(res=>{
    console.log(res,'catch by the response interceptor');
    return res;
},error=>{
    console.log(error,'error by response interceptor');
    return Promise.reject(error);
})


export default instance;