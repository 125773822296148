import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import '../JoiningComponent/joiningComponent.scss';
import logo from '../../../assets/icons/Logo-LSMeet.svg';
import endScreen from '../../../assets/icons/End-screen-icon.svg';
import endScreen2 from '../../../assets/icons/End-screen-icon1.svg';

const ExitComponent=(props) => 
{
	const welcomeMessage = "LSMeet by LIBSYS Ltd. is a Video-enabled learning platform that drive better outcomes for Educational Institutions & Corporates. It Provides a platform to extend the classroom for online students attending from a remote location.LIBSYS Ltd. has been developing software products for over 3 decades. We offer a wide range of solutions that include ‘symphonyX’ suite of SaaS offerings for Classroom Learning, Student Lifecycle and Academic Campus automation, ‘TargetX’ Online AdmissionPlatform, ‘LIBSYS’ LMS , ‘LSmart’ RFID Solutions,‘LSDiscovery’ One Search Engine and ‘LSNetX’ OmniChannel Retailing Solution, for institutes and business enterprises. Our passion for innovation and quality guides us to incorporate latest technologies and global standards in all our applications.";
	const [message , setMessage] = useState('');
	const invalidUrlMessage = "The URL that you are trying to visit might have expired or have been broken.";
	const msg1 = 'Connect, meet and collaborate with LSMeet video conferencing software.'
	const msg2 = 'LSMeet, a complete communications solution for businesses offering secure and easy to use integrated video calling without the need of separate apps.'
  	const msg3 = 'Schedule a meeting in seconds and invite participants. Join scheduled and ad-hoc meetings directly from your browser without installing any plugins. Enhance participation and teamwork with features like screen sharing, document sharing and chat.'

	useEffect(() => {
		setMessage('Call ended')
		
	}, [props.errorMessage])

	return (
		<div className='joiningRoom'>
			<header>
				<img src={logo} alt="lsmeet logo" height="43px" />
				
			</header>
			<div className="welcome-body">
				<div className="userName" > </div>
				<section className="parent-container" >
					<div className="lhs-container">
						<div style={{backgroundColor: '#434343'}} className="video-conainer" >
							{ props.errorMessage.length > 0 &&

								<div className="error404">
							<svg style={{ height: '32px', width: '32px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.94 30.94">
								<defs>
									<style>
										.cls-1 {
											//   fill: '#f5f5f5';
										}
									</style>
								</defs>
								<title>x_smiley</title>
								<g id="Layer_2" data-name="Layer 2">
									<g id="_404" data-name="404">
										<path className="cls-1"
											d="M15.47,30.94A15.47,15.47,0,1,1,30.94,15.47,15.49,15.49,0,0,1,15.47,30.94Zm0-30.19A14.72,14.72,0,1,0,30.19,15.47,14.74,14.74,0,0,0,15.47.75Z" />
										<rect className="cls-1" x="7.56" y="9.67" width="5.99" height="0.75"
											transform="translate(10.2 -4.52) rotate(45)" />
										<rect className="cls-1" x="10.18" y="7.05" width="0.75" height="5.99"
											transform="translate(10.2 -4.52) rotate(45)" />
										<rect className="cls-1" x="17.39" y="9.67" width="5.99" height="0.75"
											transform="translate(13.08 -11.47) rotate(45)" />
										<rect className="cls-1" x="20.01" y="7.05" width="0.75" height="5.99"
											transform="translate(13.08 -11.47) rotate(45)" />
										<path className="cls-1"
											d="M20,22.61c-.87-1.3-2.36-1.93-4.53-1.93s-3.66.63-4.53,1.93l-.31-.21c.95-1.41,2.53-2.1,4.84-2.1s3.9.69,4.84,2.1Z" />
									</g>
								</g>
							</svg>
						</div>
							}
							{
								props.errorMessage.length > 0 && <>
								 <p style={{fontSize : '14px' , position: 'relative', display: 'flex' , justifyContent: 'center', top : '46%', color : '#b5b5b5'}}>You've encountered</p> 
							<p style={{fontSize : '24px', fontWeight: '400', position: 'relative', color : '#b5b5b5' , textAlign : 'center' , top : '50%', fontWeight : '400'}}>{`${props.errorMessage}`}</p>
								</>
							}
							{ 
							 props.errorMessage.length == 0 && <div style={{position: 'absolute', fontWeight : '400', top: 'calc(50% - 43px)' , textAlign: 'center', left: 'calc(50% - 137px)'}}>
								 <img src={endScreen} height={'32px'} width={'32px'} />
								 <p style={{color : '#b5b5b5', fontSize : '24px', margin: '12px 0'}}>Your Camera is turned off.</p>
								  <div style={{display : 'flex', justifyContent : 'center' , marginLeft : '12px'}} className='message'>
									<img src={endScreen2} height={'32px'} width={'32px'} ></img>
								  <p style={{color : '#b5b5b5', fontSize : '24px', margin : 0, marginLeft : '14px'}} >{`${message}`}</p>
								</div>
								</div>
							}
						</div>
					</div>
					<div className="rhs-container" >
						{
							props.errorMessage ?
						<p className="title" style={{color : 'red'}}>
							{`${invalidUrlMessage}`}
							</p> : <p className="title">Thank you to join {`${props.meetingTitle}`} {`${props.meetingStartTime}`} with these settings. </p>
						}
						<hr style={{color: 'rgba(255,255,255,0.21)',marginBottom: '12px', marginTop : '20px'}} ></hr>
						<div className="description">
						{/* <p className="descriptionPara" >{welcomeMessage}</p> */}
						<div className="descriptionPara" >
								<ul style={{listStyleType : 'circle' ,listStyleType: 'disc' , listStylePosition: 'outside', paddingLeft : '20px'}}>
									<li>{msg1}</li>
									<li style={{padding : '29px 0'}}>{msg2}</li>
									<li>{msg3}</li>
								</ul>
							</div>
						{/* {
							cameraarray.length == 0 && <button className="button-container" onClick={() => { props.onClickJoinButton(); }}>Join Now</button>
						}	 */}
						</div>
					</div>
				</section>
			</div>
			<footer>
				<p> LSMeet from LIBSYS Ltd. Copyright &copy; 2022 All Rights Reserved.</p>
			</footer>
		</div>
	);
};

const month = ['Jan', 'Feb', 'March', 'April', 'May','June','July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const mapStateToProps= (state) => 
{
	return {
		meetingAuthenticated : state.MeetingControl.meetingAuthenticated,
		allowedToJoinMeeting : state.MeetingControl.mettingAllowedToJoin,
		meetingTitle : state.MeetingControl.meetingTitle,
		userName : state.me.displayName,
		meetingStartTime : new Date(state.MeetingControl.meetingStartTime).getDate() + ' ' +  month[new Date(state.MeetingControl.meetingStartTime).getMonth()]  + ' ' + new Date(state.MeetingControl.meetingStartTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true}),	
		errorMessage : state.MeetingControl.currentErrorMsg
	};
};


const ExitcomponentContainer=connect(mapStateToProps, null)(ExitComponent);

export default ExitcomponentContainer;