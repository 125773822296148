import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, withStyles } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import PropTypes from 'prop-types';

const StyledButton=withStyles({
  root:{
    backgroundColor:'#0197D6',
    color:'#fff',
    boxShadow:'0 2px 4px #00000',
    '&:hover':{
      backgroundColor:'#fff',
      color:'#0197D6',
    }
  }
})(Button);


const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, confirmButtonTitle,titleStyle } =
    props;

  return (
    <React.Fragment>
      <Dialog open={open} onClose={setOpen} >
        <DialogTitle style={{backgroundColor:'#F1F1F1', color:'#0197D6',...titleStyle}}>{title}</DialogTitle>
        <DialogContent style={{backgroundColor:'#F1F1F1'}}>{children}</DialogContent>
        <DialogActions style={{backgroundColor:'#F1F1F1'}}>
          <StyledButton variant="contained" onClick={setOpen}>
            No
          </StyledButton>
          <StyledButton
            variant="contained"
            onClick={() => {
              onConfirm();
              // console.log("Aditya dialog testing");
              setOpen();
            }}
          >
            {confirmButtonTitle ? confirmButtonTitle : "Yes"}
          </StyledButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

ConfirmDialog.propTypes={
    title:PropTypes.string.isRequired,
    open:PropTypes.bool.isRequired,
    setOpen:PropTypes.func.isRequired,
    onConfirm:PropTypes.func.isRequired,
    confirmButtonTitle:PropTypes.string,
    titleStyle:PropTypes.object,
};

export default ConfirmDialog;
