import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRoomContext } from '../../../../RoomContext';
import './chatInput.scss';
import sendButton from '../../../../assets/icons/chat-send-btn.svg';
import attachment from '../../../../assets/icons/Attachment.svg';
import audioRecorder from '../../../../assets/icons/Mike-icon-gray.svg';
import saveIcon from '../../../../assets/icons/Save-icon1.svg';
import recorderButton from '../../../../assets/icons/Rec.gif';
import DeleteGrey from '../../../../assets/icons/Delete-Grey.svg';
import useRecorder from "../../../utils/useRecorder"
import useFetchapi from '../../../utils/useFetchapi';
import axios from 'axios';
import { serviceUrl } from '../../../../urlFactory';
import UrlParse from 'url-parse';
import * as stateActions from '../../../Redux/stateActions';


const BotMessageRegex = new RegExp('^@bot (.*)');
const urlParser = UrlParse(window.location.href, true); 
const meetingId = urlParser.query.roomId;
const axiosInstance = axios.create({
	baseURL: serviceUrl,
});

const ChatInput = (props) => 
{
	console.log(props.roomId);
	const {recorderState , ...handelers} = useRecorder();
	const { recordingMinutes , recordingSeconds , initRecording } = recorderState;
	const { startRecording , saveRecording , cancelRecording } = handelers;
	const { audio , blob } = recorderState
	const [text, setText] = useState('');
	const [selectedFile, setSelectedFile] = useState('');
	const [isRecording , setIsRecording] = useState(false)

	useEffect(() => {
		console.log(audio)
		console.log(recorderState)
		if(audio){
			uploadAudio(blob);
		}
	} , [audio])
	let _textareaElem = null;
	const {
		connected,
		chatDataProducer,
		botDataProducer
	} = props;

	const disabled = !connected || (!chatDataProducer && !botDataProducer);

	const uploadAudio = async(blob) => {
			const formData = new FormData();
			formData.append('File' , blob);
			axiosInstance.post('/uploadFile', formData , {params : {'meetingId' : props.roomId , 'participantId' : props.participantId}}).then(res => res.data).then(resp => {
				console.log(resp)
				if(resp.flag == 0){
					let obj = {
							id: props.participantId,
							title: 'Me',
							text: [resp.url, resp.type , resp.name]
					}
					props.updateChat(obj);
				}
			})
		
	}

	const fileHandler = async (event) => {
		event.preventDefault();
		const selectedFile = event.target.files[0];
		if((selectedFile.type == "application/pdf" || selectedFile.type == "image/jpeg" || selectedFile.type == "image/png" || selectedFile.name.includes(".pdf") || selectedFile.name.includes(".docx") || selectedFile.name.includes(".doc") || selectedFile.name.includes(".xls") || selectedFile.name.includes(".xlsx") || selectedFile.name.includes(".odt") || selectedFile.name.includes(".ppt") || selectedFile.name.includes(".pptx")) && selectedFile.size < 10000000 ){
			await setSelectedFile(selectedFile);
			const formData = new FormData();
			formData.append('File' , selectedFile);
			axiosInstance.post('/uploadFile', formData , {params : {'meetingId' : props.roomId , 'participantId' : props.participantId}}).then(res => res.data).then(resp => {
				if(resp.flag == 0){
					let obj = {
							id: props.participantId,
							title: 'Me',
							text: [resp.url, resp.type , resp.name]
					}
					props.updateChat(obj);
				}
			})
		}
	}
 
	const handleChange = (event) => 
	{ 
		const text = event.target.value;

		setText( text );
	}

	const sendMsgOnMouseClick = async (event) => {
		event.preventDefault();

		let dupText = text.trim();
		
		console.log(selectedFile);

		setText('');

		if (dupText)
		{
			const { roomClient } = props;
			const match = BotMessageRegex.exec(dupText);

			// Chat message.
			if (!match)
			{
				dupText = text.trim();

				roomClient.sendChatMessage(text);
			}
			// Message to the bot.
			else
			{
				dupText = match[1].trim();

				roomClient.sendBotMessage(text);
			}
		}
	}
	

	const handleKeyPress = (event) =>
	{
		// If Shift + Enter do nothing.
		if (event.key !== 'Enter' || (event.shiftKey || event.ctrlKey))
			return;

		// Don't add the sending Enter into the value.
		event.preventDefault();

		let textMsg = text.trim();

		setText('');

		if (textMsg)
		{
			const { roomClient } = props;
			const match = BotMessageRegex.exec(textMsg);

			// Chat message.
			if (!match)
			{
				textMsg = textMsg.trim();

				roomClient.sendChatMessage(textMsg);
			}
			// Message to the bot.
			else
			{
				textMsg = match[1].trim();

				roomClient.sendBotMessage(textMsg);
			}
		}
	}

	const startAndStop = (event) => {
		// console.log('calling recorder')
		// console.log(recorderState);
		// console.log(handelers);
		// console.log(audio)
	}

	return (
      <div className="ChatInput">
		  {
		   !isRecording && <> <img title="Voice Recording" style={{top : '0px' , left : '6px', position : 'absolute', cursor:'pointer'}} onClick={ () => { startRecording() ; setIsRecording(true) }} src={audioRecorder} height={'27px'} width={'21px'}></img>
			
			</>
		  }
		{
			isRecording && <>
		   		<img style={{top : '0px' , left : '6px', position : 'absolute', cursor:'pointer'}} src={recorderButton} height={'28px'} width={'30px'}></img>
				<img title="Delete" style={{top : '0px' , left : '40px', position : 'absolute' , cursor:'pointer'}} onClick={() => {cancelRecording() ; setIsRecording(false) }} src={DeleteGrey} height={'27px'} width={'21px'}></img>
		 		<img title="Save" style={{top : '0px' , left : '70px', position : 'absolute', cursor:'pointer'}} onClick={() => {saveRecording() ; setIsRecording(false) }} src={saveIcon} height={'27px'} width={'21px'}></img>
			</>
		}
		
			<textarea
			ref={(elem) => {
			  _textareaElem = elem;
			}}
			placeholder={disabled ? "Chat unavailable" : "Type Message"}
			dir="auto"
			autoComplete="off"
			disabled={disabled}
			value={text}
			onChange={handleChange}
			onKeyPress={handleKeyPress}
		  />
		  <label htmlFor="attachimg">
			<img
			  style={{ marginRight: "10px", cursor: "pointer" }}
			  src={attachment}
			/>
		  </label>
		  <input
			type="file"
			name="file"
			id="attachimg"
			style={{ display: "none" }}
			onChange={fileHandler}
		  ></input>
		  <img
			onClick={sendMsgOnMouseClick}
			style={{ marginRight: "10px", cursor: "pointer" }}
			src={sendButton}
		  ></img>
		  
		
        
      </div>
    )


}

ChatInput.propTypes =
{
	roomClient       : PropTypes.any.isRequired,
	connected        : PropTypes.bool.isRequired,
	chatDataProducer : PropTypes.any,
	botDataProducer  : PropTypes.any
};

const mapStateToProps = (state) =>
{
	const dataProducersArray = Object.values(state.dataProducers);
	const roomId=state.room.roomId;
	const chatDataProducer = dataProducersArray
		.find((dataProducer) => dataProducer.label === 'chat');
	const botDataProducer = dataProducersArray
		.find((dataProducer) => dataProducer.label === 'bot');

	return {
		connected : state.room.state === 'connected',
		chatDataProducer,
		botDataProducer,
		participantId : state.me.id,
		roomId
	};
};

const mapDispatchToProps= (dispatch) => {

	return {
		updateChat :(resp)=>{
			return dispatch(stateActions.maintainChatArray(resp));
		},
}
};

const ChatInputContainer = withRoomContext(connect(
	mapStateToProps, mapDispatchToProps,
)(ChatInput));

export default ChatInputContainer;
