import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { withRoomContext } from '../../RoomContext'; 

const Test=(props)=>{
    // console.log(props.roomClient);
    let navigate=useNavigate();
    return <>
    <div>
        <h1>
            test react
        </h1>
        {
            props.login?
            <button onClick={()=>{
                navigate({
                    pathname:'/join', 
                    search:createSearchParams({
                        roomId:'libsys',
                        userId:'aditya'
                    }).toString(),
                });
            }}>Succesfully logged in</button>
            :
            <button onClick={()=>{
            navigate({
                pathname:'/join', 
                search:createSearchParams({
                    roomId:'libsys',
                    userId:'admin'
                }).toString(),
            });
        }}>LsMeet</button>
        }
        
    </div>
    </>
}

const TestContainer=withRoomContext(Test)

export default TestContainer;