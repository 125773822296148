import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { withRoomContext } from "../../../../../RoomContext";
import "./ParticipantListIconTray.scss";
import unMuteMicImg from "../../../../../assets/icons/icon_Mike-Unmute.svg";
import muteMicImg from "../../../../../assets/icons/Add-Participate1.svg";
import enableVideoImg from "../../../../../assets/icons/Video-icon-black.svg";
import disableVideoImg from "../../../../../assets/icons/icon_Video-deactive.svg";
import kickOut from "../../../../../assets/icons/icon_Kickout.svg";
import handRaise from '../../../../../assets/icons/White-Hand.svg';

const ParticipantListIconTray = (props) => {
    // console.log(props.roomClient, "participant list icon tray", props.id);
    const audioRef = useRef(null);
    const audioEnabled =
        Boolean(props.audioConsumer) &&
        !props.audioConsumer.locallyPaused &&
        !props.audioConsumer.remotelyPaused;
    const videoVisible =
        Boolean(props.videoConsumer) &&
        !props.videoConsumer.locallyPaused &&
        !props.videoConsumer.remotelyPaused;

    useEffect(() => {
        if (props.audioConsumer) {
            const stream = new MediaStream();
            stream.addTrack(props.audioConsumer.track);
            // console.log(audioRef, stream);
            audioRef.current.srcObject = stream;
            audioRef.current
                .play()
                .catch((error) =>
                    console.log(error, "1234", "1234", "audio teack failed")
                );
        }
    }, [props.audioConsumer]);

    return (
        <>
            {
                props.isHandRaised && (
                    <img
                        src={handRaise}
                        className="controlIcon handRaiseIcon"
                        alt="Hand Raised"
                        data-tip={'Hand is Raised by this User'}
                    />
                )
            }
            {audioEnabled && (
                <img
                    src={unMuteMicImg}
                    className="controlIcon"
                    alt="mic on"
                    title={'Mute this User'}
                    onClick={async () => {
                        // console.log(props.id);
                        // await props.roomClient._protoo.request("unmuteAudio",{presenterId:"admin",peerId:id});
                        props.roomClient.muteMicRequest(props.id);
                    }}
                />
            )}
            {!audioEnabled && (
                <img
                    src={muteMicImg}
                    className="controlIcon"
                    alt="mic off"
                    title={'Unmute this User'}
                    onClick={async () => {
                        // console.log(props.id);
                        //    await props.roomClient._protoo.request("unmuteAudio",{presenterId:"admin",peerId:id});
                        props.roomClient.changeDisplayName(props.id);
                    }}
                />
            )}
            {videoVisible && (
                <img
                    src={enableVideoImg}
                    className="controlIcon"
                    alt="video on"
                    title={'Disable Video of this User'}
                    onClick={async () => {
                        // console.log(props.id);
                        props.roomClient.disableWebcamRequest(props.id);
                    }}
                />
            )}
            {!videoVisible && (
                <img
                    src={disableVideoImg}
                    className="controlIcon"
                    alt="video off"
                    title={'Enable Video of this User'}
                    onClick={async () => {
                        // console.log(props.id);
                        props.roomClient.enableWebcamRequest(props.id);
                    }}
                />
            )}

            {
                props.isPresenter && <img
                    src={kickOut}
                    className="controlIcon kickOutIcon"
                    alt="Remove from Meeting"
                    title="Remove User"
                    onClick={async () => {
                        // console.log(props.id);
                        props.roomClient.kickOutParticipant(props.id);
                    }}
                />
            }

            {/* <If condition={audioEnabled}><img src="../../resources/icons/options/icon_Mike-Unmute.svg" height={'24px'} width={'24px'} style={{marginLeft: '12px' , cursor: 'pointer'}} alt="mic on" onClick={async ()=>{
                console.log(props.id);
                // await props.roomClient._protoo.request("unmuteAudio",{presenterId:"admin",peerId:id});
                props.roomClient.muteMicRequest(props.id);
                }} /></If>
            <If condition={!audioEnabled}><img src="../../resources/icons/options/Add-Participate1.svg" height={'24px'} width={'24px'} style={{marginLeft: '12px', cursor: 'pointer'}} alt="mic off" onClick={async()=>{
                console.log(props.id);
            //    await props.roomClient._protoo.request("unmuteAudio",{presenterId:"admin",peerId:id});
                props.roomClient.changeDisplayName(props.id);
                }} /></If>
            <If condition={videoVisible}><img src="../../resources/icons/options/Video-icon-black.svg" height={'24px'} width={'24px'} style={{marginLeft: '12px', cursor: 'pointer'}} alt="video on" onClick={async()=>{
                console.log(props.id);
                props.roomClient.disableWebcamRequest(props.id);
                }} /></If>
            <If condition={!videoVisible}><img src="../../resources/icons/options/icon_Video-deactive.svg" height={'24px'} width={'24px'} style={{marginLeft: '12px', cursor: 'pointer'}} alt="video off" onClick={async()=>{
                console.log(props.id);
                props.roomClient.enableWebcamRequest(props.id);
                }}/></If> */}
            <audio ref={audioRef} autoPlay playsInline controls={false} />
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const peer = state.peers[ownProps.id];
    const isHandRaised=state.MeetingControl.handRaisedBy.includes(ownProps.id);
    const isPresenter=state.MeetingControl.presenterId===state.me.id;
    const consumersArray = peer?.consumers?.map(
        (consumerId) => state.consumers[consumerId]
    );
    const audioConsumer = consumersArray?.find(
        (consumer) => consumer.track.kind === "audio"
    );
    const videoConsumer = consumersArray?.find(
        (consumer) => consumer.track.kind === "video"
    );

    return {
        audioConsumer: audioConsumer,
        videoConsumer: videoConsumer,
        isHandRaised,
        isPresenter
    };
};

const mapDispatchToProps = () => { };

const ParticipantListIconTrayContainer = withRoomContext(
    connect(mapStateToProps, null)(ParticipantListIconTray)
);

export default ParticipantListIconTrayContainer;
