import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './stats.scss';
// import { Appear } from './transitions';
import { withRoomContext } from '../../../RoomContext';
import * as stateActions from '../../Redux/stateActions';
import Modal from '@material-ui/core/Modal';
import ParicipantChatPanel  from '../ParticipantListAndChatPanel/ParticipantChatPanel';
import  copyLinkIcon from '../../../assets/icons/copy-icon.svg';
import infoIcon from '../../../assets/icons/info-icon.svg';
import crossIcon from '../../../assets/icons/Cross-btn.svg';

class Stats extends React.Component {
	constructor(props) {
		super(props);

		this.state =
			{
				sendTransportRemoteStats: null,
				sendTransportLocalStats: null,
				recvTransportRemoteStats: null,
				recvTransportLocalStats: null,
				audioProducerRemoteStats: null,
				audioProducerLocalStats: null,
				videoProducerRemoteStats: null,
				videoProducerLocalStats: null,
				chatDataProducerRemoteStats: null,
				botDataProducerRemoteStats: null,
				audioConsumerRemoteStats: null,
				audioConsumerLocalStats: null,
				videoConsumerRemoteStats: null,
				videoConsumerLocalStats: null,
				chatDataConsumerRemoteStats: null,
				botDataConsumerRemoteStats: null,
				openModal: false,
				addParticipantEmail : '',
				addParticipantName : '',
				addParticipantId : '',	
			};

		this._delayTimer = null;
		this.setOpenModal = this.setOpenModal.bind(this);
		this.copyLinkButton=React.createRef();
		this.handleInputChange = this.handleInputChange.bind(this)
	}

	setOpenModal() {
		let currentModalState = this.state.openModal;
		this.setState({ ...this.state, openModal: !currentModalState });
	}
	handleInputChange(event , type){
		const value = event.target.value;
		if(type === 'email')
			this.setState({...this.state , addParticipantEmail : value })
		if(type === 'name')
			this.setState({...this.state , addParticipantName : value})
		if(type === 'userid')
			this.setState({...this.state , addParticipantId : value})
	}

	render() {
		const {
			peerId,
			peerDisplayName,
			isMe,
			onClose
		} = this.props;
		const {
			sendTransportRemoteStats,
			sendTransportLocalStats,
			recvTransportRemoteStats,
			recvTransportLocalStats,
			audioProducerRemoteStats,
			audioProducerLocalStats,
			videoProducerRemoteStats,
			videoProducerLocalStats,
			chatDataProducerRemoteStats,
			botDataProducerRemoteStats,
			audioConsumerRemoteStats,
			audioConsumerLocalStats,
			videoConsumerRemoteStats,
			videoConsumerLocalStats,
			chatDataConsumerRemoteStats,
			botDataConsumerRemoteStats
		} = this.state;

		return (
			<div className='Stats'>
				<div className={classnames('content', { visible: peerId })}>
					<div className='chat-and-participant'>
						<div className='parent-container'> 
							<div className='status-container'> 
							{
								this.props.MeetingControl.meetingType === 'open' && <div title='info' className='addParticipants' onClick={this.setOpenModal} ></div>
							}
							 <div title={`${this.props.MeetingControl.meetingTitle}`} className='timer' style={this.props.me.id===this.props.MeetingControl.presenterId ? {position : 'relative'} : {position : 'absolute' , left: 'calc(50% - 48px)'}} >{this.props.timeformat}  </div>
								{this.props.me.id===this.props.MeetingControl.presenterId &&<div style={{position : 'relative' , right : '12px', display: 'flex'}}>
									<div className='icon mic-off' data-tip={'Turn Off Mic for Participants'} data-place={'bottom'} onClick={()=>{
										this.props.roomClient.micDisableForce();
									}}/>
									<div className='icon webcam-off'data-tip={'Turn Off Camera for Participants'} data-place={'bottom'} onClick={()=>{
										this.props.roomClient.videoDisableForce();
									}}/>
								{/* <If condition={!audioEnabled}>
									<div className='icon mic-off' />
								</If>
								<If condition={audioEnabled}>
									<div className='icon mic-on' />
								</If> 
								<If condition={!videoConsumer}>
									<div className='icon webcam-off' />
								</If>
								<If condition={videoConsumer}>
									<div className='icon webcam-on' />
								</If> */}
									
								</div>}
								</div>
							<ParicipantChatPanel timeformat={this.props.timeformat} /> 
						</div>
					</div>
					{/* <div className='header'>
						<div className='info'>
							<div
								className='close-icon'
								onClick={onClose}
							/>

							<Choose>
								<When condition={isMe}>
									<h1>Your Stats</h1>
								</When>

								<Otherwise>
									<h1>Stats of {peerDisplayName}</h1>
								</Otherwise>
							</Choose>
						</div>

						<div className='list'>
							<If condition={sendTransportRemoteStats || sendTransportLocalStats}>
								<p>
									{'send transport stats: '}
									<a href='#send-transport-remote-stats'>[remote]</a>
									<span>{' '}</span>
									<a href='#send-transport-local-stats'>[local]</a>
								</p>
							</If>

							<If condition={recvTransportRemoteStats || recvTransportLocalStats}>
								<p>
									{'recv transport stats: '}
									<a href='#recv-transport-remote-stats'>[remote]</a>
									<span>{' '}</span>
									<a href='#recv-transport-local-stats'>[local]</a>
								</p>
							</If>

							<If condition={audioProducerRemoteStats || audioProducerLocalStats}>
								<p>
									{'audio producer stats: '}
									<a href='#audio-producer-remote-stats'>[remote]</a>
									<span>{' '}</span>
									<a href='#audio-producer-local-stats'>[local]</a>
								</p>
							</If>

							<If condition={videoProducerRemoteStats || videoProducerLocalStats}>
								<p>
									{'video producer stats: '}
									<a href='#video-producer-remote-stats'>[remote]</a>
									<span>{' '}</span>
									<a href='#video-producer-local-stats'>[local]</a>
								</p>
							</If>

							<If condition={chatDataProducerRemoteStats}>
								<p>
									{'chat dataproducer stats: '}
									<a href='#chat-dataproducer-remote-stats'>[remote]</a>
									<span>{' '}</span>
									<a className='disabled'>[local]</a>
								</p>
							</If>

							<If condition={botDataProducerRemoteStats}>
								<p>
									{'bot dataproducer stats: '}
									<a href='#bot-dataproducer-remote-stats'>[remote]</a>
									<span>{' '}</span>
									<a className='disabled'>[local]</a>
								</p>
							</If>

							<If condition={audioConsumerRemoteStats || audioConsumerLocalStats}>
								<p>
									{'audio consumer stats: '}
									<a href='#audio-consumer-remote-stats'>[remote]</a>
									<span>{' '}</span>
									<a href='#audio-consumer-local-stats'>[local]</a>
								</p>
							</If>

							<If condition={videoConsumerRemoteStats || videoConsumerLocalStats}>
								<p>
									{'video consumer stats: '}
									<a href='#video-consumer-remote-stats'>[remote]</a>
									<span>{' '}</span>
									<a href='#video-consumer-local-stats'>[local]</a>
								</p>
							</If>

							<If condition={chatDataConsumerRemoteStats}>
								<p>
									{'chat dataconsumer stats: '}
									<a href='#chat-dataconsumer-remote-stats'>[remote]</a>
									<span>{' '}</span>
									<a className='disabled'>[local]</a>
								</p>
							</If>

							<If condition={botDataConsumerRemoteStats}>
								<p>
									{'bot dataconsumer stats: '}
									<a href='#bot-dataconsumer-remote-stats'>[remote]</a>
									<span>{' '}</span>
									<a className='disabled'>[local]</a>
								</p>
							</If>
						</div>
					</div>

					<div className='stats'>
						<If condition={sendTransportRemoteStats}>
							{this._printStats('send transport remote stats', sendTransportRemoteStats)}
						</If>

						<If condition={sendTransportLocalStats}>
							{this._printStats('send transport local stats', sendTransportLocalStats)}
						</If>

						<If condition={recvTransportRemoteStats}>
							{this._printStats('recv transport remote stats', recvTransportRemoteStats)}
						</If>

						<If condition={recvTransportLocalStats}>
							{this._printStats('recv transport local stats', recvTransportLocalStats)}
						</If>

						<If condition={audioProducerRemoteStats}>
							{this._printStats('audio producer remote stats', audioProducerRemoteStats)}
						</If>

						<If condition={audioProducerLocalStats}>
							{this._printStats('audio producer local stats', audioProducerLocalStats)}
						</If>

						<If condition={videoProducerRemoteStats}>
							{this._printStats('video producer remote stats', videoProducerRemoteStats)}
						</If>

						<If condition={videoProducerLocalStats}>
							{this._printStats('video producer local stats', videoProducerLocalStats)}
						</If>

						<If condition={chatDataProducerRemoteStats}>
							{this._printStats('chat dataproducer remote stats', chatDataProducerRemoteStats)}
						</If>

						<If condition={botDataProducerRemoteStats}>
							{this._printStats('bot dataproducer remote stats', botDataProducerRemoteStats)}
						</If>

						<If condition={audioConsumerRemoteStats}>
							{this._printStats('audio consumer remote stats', audioConsumerRemoteStats)}
						</If>

						<If condition={audioConsumerLocalStats}>
							{this._printStats('audio consumer local stats', audioConsumerLocalStats)}
						</If>

						<If condition={videoConsumerRemoteStats}>
							{this._printStats('video consumer remote stats', videoConsumerRemoteStats)}
						</If>

						<If condition={videoConsumerLocalStats}>
							{this._printStats('video consumer local stats', videoConsumerLocalStats)}
						</If>

						<If condition={chatDataConsumerRemoteStats}>
							{this._printStats('chat dataconsumer remote stats', chatDataConsumerRemoteStats)}
						</If>

						<If condition={botDataConsumerRemoteStats}>
							{this._printStats('bot dataconsumer remote stats', botDataConsumerRemoteStats)}
						</If>
					</div> */}
				</div>
				<Modal aria-labelledby='simple-modal' aria-describedby='simple-modal-desc' open={this.state.openModal} onClose={this.setOpenModal}>
					<div style={{ position: 'relative',top: 'calc(50% - 15.5vh)',borderRadius : '4px',padding : '10px' , backgroundColor : '#FFF' , boxShadow : '0 10px 6px rgb(0, 0, 0, 0.16)' , margin: 'auto', height: '26vh', width: '37vw' }}>
						<div style={{ padding: '7px 0', borderBottom: ' 1px solid rgb(112 112 112 / 40%)' , height : '36px', position : 'relative' }}>
							<span style={{ fontSize: '18px', color: '#151515', width: '100%', textAlign: 'left', fontWeight: '400' }} >{this.props.MeetingControl.meetingType === "open" ? 'Meeting Information' : 'Add Participant'} </span>
							<img style={{position:'absolute' , top:'3px', right:'12px', cursor: 'pointer' }} onClick={this.setOpenModal} src={crossIcon} height={'28px'} width={'28px'} ></img>
						</div>	
							
						{ 
							this.props.MeetingControl.meetingType === "open" && <>
							<div style={{padding : '14px' , height : 'calc(100% - 36px)' , position : 'relative' , overflow : 'auto' }} >
								<div className='openMeetingData' >
									<span style={{minWidth : '120px' }} ><strong>Room ID</strong> </span> <strong>:</strong> <span style={{paddingLeft : '12px' , width : 'calc(100% - 120px)' }} >{this.props.roomId}</span> 
								</div>
								<div className='openMeetingData' >
									<span style={{minWidth : '120px' }} ><strong>Meeting Title</strong> </span> <strong>:</strong> <span style={{paddingLeft : '12px' , width : 'calc(100% - 120px)'}} >{this.props.MeetingControl.meetingTitle}</span> 
								</div>
								<div className='openMeetingData' >
									<span style={{minWidth : '120px' , maxWidth : '120px'}} > <strong>Meeting Start Time</strong></span> <strong>:</strong> <span style={{paddingLeft : '12px' , width : 'calc(100% - 120px)'}}>{new Date(this.props.MeetingControl.meetingStartTime).toLocaleString()}</span> 
								</div>
								<div style={{display : 'flex' , width : '100%' , justifyContent : 'flex-end' }} >
								<button ref={ref=>this.copyLinkButton=ref} data-tip='Copy Link' className='button setButton' onClick={() => { 
									if(navigator.clipboard)
										navigator.clipboard.writeText(window.location.href);
										
										this.copyLinkButton.setAttribute('data-tip','Copied')
										ReactTooltip.rebuild();
										ReactTooltip.show(this.copyLinkButton)

								 }} ><img src={copyLinkIcon}></img> Meeting Link</button>
								</div>

							</div>
							</> 
						}		 
						{
							this.props.MeetingControl.meetingType === "closed" && <>
							<form>
								<input className='form-fields' value={this.state.addParticipantName} onChange={ (event) => { this.handleInputChange(event, 'name') }} placeholder='Name' ></input>
								<input className='form-fields' value={this.state.addParticipantId} onChange={ (event) => { this.handleInputChange(event, 'userid') }} placeholder='User Id' ></input>
								<input className='form-fields' value={this.state.addParticipantEmail} onChange={ (event) => { this.handleInputChange(event, 'email') }} placeholder='E-mail Id' ></input>

								<button className='button setInviteButton' onClick={(event) => {
									 event.preventDefault() ;this.state.addParticipantId = '';this.state.addParticipantName = '';this.state.addParticipantEmail='';
									 console.log('abc')
									 }} disabled={!this.state.addParticipantName.length || !this.state.addParticipantId.length || !this.state.addParticipantEmail.includes('@gmail.com')} >Invite</button>
							</form>
							</>
						}	
						<ReactTooltip
					type='info'
					effect='solid'
					multiline
					delayShow={100}
					delayHide={100}
					delayUpdate={50}
				/>
					</div>
				</Modal>
				
			</div>
		);
	}

	componentDidUpdate(prevProps) {
		const { peerId } = this.props;
		ReactTooltip.rebuild();

		if (peerId && !prevProps.peerId) {
			this._delayTimer = setTimeout(() => this._start(), 250);
		}
		else if (!peerId && prevProps.peerId) {
			this._stop();
		}
		else if (peerId && prevProps.peerId && peerId !== prevProps.peerId) {
			this._stop();
			this._start();
		}
	}

	async _start() {
		const {
			roomClient,
			isMe,
			audioConsumerId,
			videoConsumerId,
			chatDataConsumerId,
			botDataConsumerId
		} = this.props;

		let sendTransportRemoteStats = null;
		let sendTransportLocalStats = null;
		let recvTransportRemoteStats = null;
		let recvTransportLocalStats = null;
		let audioProducerRemoteStats = null;
		let audioProducerLocalStats = null;
		let videoProducerRemoteStats = null;
		let videoProducerLocalStats = null;
		let chatDataProducerRemoteStats = null;
		let botDataProducerRemoteStats = null;
		let audioConsumerRemoteStats = null;
		let audioConsumerLocalStats = null;
		let videoConsumerRemoteStats = null;
		let videoConsumerLocalStats = null;
		let chatDataConsumerRemoteStats = null;
		let botDataConsumerRemoteStats = null;

		if (isMe) {
			sendTransportRemoteStats = await roomClient.getSendTransportRemoteStats()
				.catch(() => { });

			sendTransportLocalStats = await roomClient.getSendTransportLocalStats()
				.catch(() => { });

			recvTransportRemoteStats = await roomClient.getRecvTransportRemoteStats()
				.catch(() => { });

			recvTransportLocalStats = await roomClient.getRecvTransportLocalStats()
				.catch(() => { });

			audioProducerRemoteStats = await roomClient.getAudioRemoteStats()
				.catch(() => { });

			audioProducerLocalStats = await roomClient.getAudioLocalStats()
				.catch(() => { });

			videoProducerRemoteStats = await roomClient.getVideoRemoteStats()
				.catch(() => { });

			videoProducerLocalStats = await roomClient.getVideoLocalStats()
				.catch(() => { });

			chatDataProducerRemoteStats = await roomClient.getChatDataProducerRemoteStats()
				.catch(() => { });

			botDataProducerRemoteStats = await roomClient.getBotDataProducerRemoteStats()
				.catch(() => { });

			botDataConsumerRemoteStats =
				await roomClient.getDataConsumerRemoteStats(botDataConsumerId)
					.catch(() => { });
		}
		else {
			audioConsumerRemoteStats = await roomClient.getConsumerRemoteStats(audioConsumerId)
				.catch(() => { });

			audioConsumerLocalStats = await roomClient.getConsumerLocalStats(audioConsumerId)
				.catch(() => { });

			videoConsumerRemoteStats = await roomClient.getConsumerRemoteStats(videoConsumerId)
				.catch(() => { });

			videoConsumerLocalStats = await roomClient.getConsumerLocalStats(videoConsumerId)
				.catch(() => { });

			chatDataConsumerRemoteStats =
				await roomClient.getDataConsumerRemoteStats(chatDataConsumerId)
					.catch(() => { });
		}

		this.setState(
			{
				sendTransportRemoteStats,
				sendTransportLocalStats,
				recvTransportRemoteStats,
				recvTransportLocalStats,
				audioProducerRemoteStats,
				audioProducerLocalStats,
				videoProducerRemoteStats,
				videoProducerLocalStats,
				chatDataProducerRemoteStats,
				botDataProducerRemoteStats,
				audioConsumerRemoteStats,
				audioConsumerLocalStats,
				videoConsumerRemoteStats,
				videoConsumerLocalStats,
				chatDataConsumerRemoteStats,
				botDataConsumerRemoteStats
			});

		this._delayTimer = setTimeout(() => this._start(), 2500);
	}

	_stop() {
		clearTimeout(this._delayTimer);

		this.setState(
			{
				sendTransportRemoteStats: null,
				sendTransportLocalStats: null,
				recvTransportRemoteStats: null,
				recvTransportLocalStats: null,
				audioProducerRemoteStats: null,
				audioProducerLocalStats: null,
				videoProducerRemoteStats: null,
				videoProducerLocalStats: null,
				chatDataProducerRemoteStats: null,
				botDataProducerRemoteStats: null,
				audioConsumerRemoteStats: null,
				audioConsumerLocalStats: null,
				videoConsumerRemoteStats: null,
				videoConsumerLocalStats: null,
				chatDataConsumerRemoteStats: null,
				botDataConsumerRemoteStats: null
			});
	}

	_printStats(title, stats) {
		// const anchor = title
		// 	.replace(/[ ]+/g, '-');

		// if (typeof stats.values === 'function')
		// 	stats = Array.from(stats.values());

		// return (
		// 	<Appear duration={0}>
		// 		<div className='items'>
		// 			<h2 id={anchor}>{title}</h2>

		// 			{
		// 				stats.map((item, idx) => (
		// 					<div className='item' key={idx}>
		// 						{
		// 							Object.keys(item).map((key) => (
		// 								<div className='line' key={key}>
		// 									<p className='key'>{key}</p>
		// 									<div className='value'>
		// 										<Choose>
		// 											<When condition={typeof item[key] === 'number'}>
		// 												{JSON.stringify(Math.round(item[key] * 100) / 100, null, '  ')}
		// 											</When>

		// 											<Otherwise>
		// 												<pre>{JSON.stringify(item[key], null, '  ')}</pre>
		// 											</Otherwise>
		// 										</Choose>
		// 									</div>
		// 								</div>
		// 							))
		// 						}
		// 					</div>
		// 				))
		// 			}
		// 		</div>
		// 	</Appear>
		// );
	}
}

Stats.propTypes =
	{
		roomClient: PropTypes.any.isRequired,
		peerId: PropTypes.string,
		peerDisplayName: PropTypes.string,
		isMe: PropTypes.bool,
		audioConsumerId: PropTypes.string,
		videoConsumerId: PropTypes.string,
		chatDataConsumerId: PropTypes.string,
		botDataConsumerId: PropTypes.string,
		onClose: PropTypes.func.isRequired
	};

const mapStateToProps = (state) => {
	const { room, me, peers, consumers, dataConsumers,MeetingControl } = state;
	const { statsPeerId, roomId } = room;
	// const MeetingControl = MeetingControl;
	
	

	if (!statsPeerId)
		return {
			MeetingControl,
			me
		};

	const isMe = statsPeerId === me.id;
	const peer = isMe ? me : peers[statsPeerId];
	let audioConsumerId;
	let videoConsumerId;
	let chatDataConsumerId;
	let botDataConsumerId;

	if (isMe) {
		for (const dataConsumerId of Object.keys(dataConsumers)) {
			const dataConsumer = dataConsumers[dataConsumerId];

			if (dataConsumer.label === 'bot')
				botDataConsumerId = dataConsumer.id;
		}
	}
	else {
		for (const consumerId of peer.consumers) {
			const consumer = consumers[consumerId];

			switch (consumer.track.kind) {
				case 'audio':
					audioConsumerId = consumer.id;
					break;

				case 'video':
					videoConsumerId = consumer.id;
					break;
			}
		}

		for (const dataConsumerId of peer.dataConsumers) {
			const dataConsumer = dataConsumers[dataConsumerId];

			if (dataConsumer.label === 'chat')
				chatDataConsumerId = dataConsumer.id;

		}
	}

	return {
		peerId: peer.id,
		peerDisplayName: peer.displayName,
		me,
		isMe,
		audioConsumerId,
		videoConsumerId,
		chatDataConsumerId,
		botDataConsumerId,
		MeetingControl,
		roomId
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onClose: () => dispatch(stateActions.setRoomStatsPeerId(null))
	};
};

const StatsContainer = withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps
)(Stats));

export default StatsContainer;
