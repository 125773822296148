const initialState = [];

const chatData = (state = initialState, action) => {
	switch (action.type) {
		case 'INCOMING_CHAT_DATA':
			// eslint-disable-next-line no-case-declarations
			const newChatData = action.payload;
			let timeString=new Date().toLocaleTimeString('en-US',{hour12:'true',hour:'numeric',minute:'numeric'});

			let textObj={
				msg:newChatData.text[0],
				timeStamp:timeString,
				type : newChatData.text[1],
				name : newChatData.text[2]
			}
			let updatedMsg={
				id:newChatData.id,
				title:newChatData.title,
				text:[textObj]
			}
			if (state.length && state[state.length - 1].id == updatedMsg.id) {
				let chatArray = state;
				// console.log(chatArray);
				let lastMsg = chatArray[chatArray.length - 1];
				lastMsg.text=[...lastMsg.text,...updatedMsg.text];
				chatArray[chatArray.length - 1]=lastMsg;
				return [...chatArray];	
			}
			// if(state[state.length-1].id==newChatData.id){
			else{
			return [...state, updatedMsg];
		}



		default:
			return state;
	}

};

export default chatData;
