import React, { useLayoutEffect, useState, useEffect } from 'react';
import { withRoomContext } from '../../../../RoomContext';
import './deviceSetting.scss';
import { connect } from 'react-redux';
import * as stateActions from '../../../Redux/stateActions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import selectedAudio from '../../../../assets/icons/Audio1.svg';
import notSelectedAudio from '../../../../assets/icons/Audio2.svg';
import selectedVideo from '../../../../assets/icons/Video1.svg';
import notSelectedVideo from '../../../../assets/icons/Video2.svg';
import deleteIcon from '../../../../assets/icons/Delete-White.svg';

const DeviceSetting = (props) => 
{
	console.log(props, Array.from(props.roomClient._webcams.values()), props.roomClient._webcam.device, props.selectedMic);
	const [ allWebcams, setAllWebcams ] = useState(Array.from(props.roomClient._webcams.values()));
	const [ allMics, setAllMics ] = useState([]);
	const [ currentSelectedWebcam, setCurrentSelectedWebcam ] = useState(props.currentSelectedWebcams);
	const [ currentSelectedMic, setCurrentSelectedMic ] = useState(props.selectedMic);
	const [ selectedSetting, setSelectedSetting ] = useState('audio');

	const getAudioTracks = async () => 
	{
		const devices = await navigator.mediaDevices.enumerateDevices();
		const duplicatae = [];

		devices.forEach((device) => 
		{
			if (device.kind === 'audioinput') 
			{
				// let duplicatae=[...allMics,device];
				// console.log(duplicatae,'ghfhg ghfcg hgfhg');
				// setAllMics(duplicatae); 
				duplicatae.push(device);
			}
		});
		setAllMics(duplicatae);
		// console.log(allMics, 'all mics');
	};

	useEffect(() => 
	{
		getAudioTracks();
	}, []);

	const addnewStream=()=>{
		let idArrayOfCameras=currentSelectedWebcam.map(webcam=>webcam.cameraId);
		console.log(currentSelectedWebcam,idArrayOfCameras);
		const cameraIdForNewStream=allWebcams.find(webcam=>!idArrayOfCameras.includes(webcam.deviceId));
		console.log(cameraIdForNewStream);
		if(cameraIdForNewStream){
			//if we are able to find a camera which is connected but whose stream is not being obtained
			let cameraObject={cameraId:cameraIdForNewStream.deviceId,label:cameraIdForNewStream.label}
			console.log(cameraObject);
			setCurrentSelectedWebcam([...currentSelectedWebcam,cameraObject]);
		}else{
			alert('No More Camera Are Accessible By Browser!!! Check Your Camera Permission');
		}
		
	}

	const deleteStream=(index)=>{
		console.log(currentSelectedWebcam);
		const streamArray=[...currentSelectedWebcam];
		streamArray.splice(index,1);
		console.log(streamArray,currentSelectedWebcam);
		setCurrentSelectedWebcam(streamArray);
	}

	// const button = {
	// 	cursor          : 'pointer',
	// 	display         : 'flex',
	// 	alignItems      : 'center',
	// 	justifyContent  : 'center',
	// 	width           : '97px',
	// 	height          : '34px',
	// 	borderRadius    : '37px',
	// 	fontSize        : '14px',
	// 	textAlign       : 'center',
	// 	backgroundColor : '#0197D6',
	// 	boxShadow       : '0 3px 6px rgba(0, 0, 0, 0.16)',
	// 	border          : 'none',
	// 	marginRight     : '10px',
	// 	color           : 'white'
	// };

	console.log(allWebcams, currentSelectedWebcam, allMics, 'its here ');
	
	return (
		<div style={{ padding: '34px', height: '100%', position: 'relative' }}>
			<div style={{ padding: '19px 0', borderBottom: ' 1px solid rgb(112 112 112 / 40%)' }}>
				<span style={{ fontSize: '22px', color: '#151515', width: '100%', textAlign: 'left', fontWeight: '400' }} >Settings</span>
			</div>
			<div style={{ paddingTop: '20px', height: 'calc(100% - 58px)', display: 'grid', gridTemplateColumns: '28%  70%', gridColumnGap: '20px' }}>
				<div style={{ borderRight: '1px solid rgb(112 112 112 / 40%)' }} >
					<div style={{ height: '44px', fontSize: '18px', cursor: 'pointer', borderRadius: '8px 0 0 8px', display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft : '12px', fontWeight: '400', backgroundColor: selectedSetting == 'audio' ? '#0197D6' : 'transparent', color: selectedSetting == 'audio' ? 'white' : '#000' }} onClick={() => setSelectedSetting('audio')} >
						{
							selectedSetting === 'audio' &&
							<img style={{ marginRight: '12px' }} height={'30px'} width={'30px'} src={selectedAudio} alt='audio device selected'/>
						}
						{
							selectedSetting !== 'audio' &&
							<img style={{ marginRight: '12px' }} height={'30px'} width={'30px'} src={notSelectedAudio} alt='audio device not selected'/>
						}

						Audio
					</div>
					<div style={{ height: '44px', fontSize: '18px', cursor: 'pointer', borderRadius: '8px 0 0 8px', display: 'flex', alignItems: 'center', justifyContent: 'left',  paddingLeft : '12px', fontWeight: '400', marginTop: '25px', backgroundColor: selectedSetting == 'video' ? '#0197D6' : 'transparent', color: selectedSetting == 'video' ? 'white' : '#000' }} onClick={() => setSelectedSetting('video')} >
						{
							selectedSetting === 'video' &&
							<img style={{ marginRight: '12px' }} height={'30px'} width={'30px'} src={selectedVideo} alt='video device selected'/>
						}
						{
							selectedSetting !== 'video' &&
							<img style={{ marginRight: '12px' }} height={'30px'} width={'30px'} src={notSelectedVideo} alt='video device not selected'/>
						}
						Video
					</div>
				</div>
				<div>
					{
						selectedSetting == 'video' &&
						<div >
								<div style={{display:'flex'}}>
									<div style={{ fontSize: '20px', color: '#151515', width: '100%', textAlign: 'left', fontWeight: '400', paddingBottom: '18px' }}>Video Devices</div>
									<button className='button' style={{width:'37px',fontSize:'27px'}} title='Add New Device' onClick={addnewStream}>+</button>
								</div>

								{
									currentSelectedWebcam.map((webcam, index) => {
										return <div key={webcam.cameraId} style={{display:'flex',justifyContent:'space-between',marginBottom:'12px'}}>
											<Select style={{ minWidth: '80%', maxWidth: '80%' }} id='select-webcam' value={webcam.cameraId } label='Camera' onChange={(event) => {
												if(!currentSelectedWebcam.includes(event.target.value)){
													const newWebcamArray = [...currentSelectedWebcam];
													const cameraId=event.target.value
													newWebcamArray[index] = {cameraId,label:allWebcams.find(camera=>camera.deviceId===cameraId).label}
													setCurrentSelectedWebcam(newWebcamArray);
												}else{
													alert("you can't make multiple streams from a single camera");
												}
											}}>
												{allWebcams.map((webcam) => {

													console.log(webcam.deviceId, props.currentSelectedWebcams,allWebcams);
													return <MenuItem key={webcam.deviceId} value={webcam.deviceId}>{webcam.label}</MenuItem>;

												})}
											</Select>
											{index!==0 && <button className='button' style={{width:'37px'}} title='Delete This Device' onClick={() => {
												deleteStream(index)
											}}> <img src={deleteIcon} alt="delete" style={{width:'20px'}} /> </button>}
										</div>
									})
								}

						</div>

					}
					{
						selectedSetting == 'audio' &&
						<div >
							<div style={{ fontSize: '20px', color: '#151515', width: '100%', textAlign: 'left', fontWeight: '400', padding: '0 0 18px 0' }}> Audio</div>
							<Select style={{ minWidth: '60%', maxWidth: '60%' }} id='select-mic' value={currentSelectedMic} label='Mic' onChange={(event) => { setCurrentSelectedMic(event.target.value); }}>
								{allMics.map((mic) => 
								{
                        			return <MenuItem key={mic.deviceId} value={mic.deviceId}>{mic.label}</MenuItem>;
                        			// return <h2>{mic.label}</h2>
                        		})}
							</Select>
						</div>

					}
					<div style={{ position: 'absolute', display: 'flex', bottom: '34px', right: '34px' }}>
					<button className='button'  onClick={() => 
						{

							props.toCloseModal();
						}}
						>Cancel</button>
						<button className='button'  onClick={async () => 
						{
							if (currentSelectedWebcam  !== props.currentSelectedWebcams) 
							{
								props.updateWebcamId(currentSelectedWebcam);
								console.log(' camera is getting toggled ', currentSelectedWebcam[0],props.roomClient._webcam.device.deviceId);
								if(props.shareInProgress){
									await props.roomClient.disableShare();
								}else{
									await props.roomClient.disableWebcam();
									await props.roomClient.enableWebcam();
								}
								
							}
							if (props.selectedMic !== currentSelectedMic) 
							{
								props.updateMicId(currentSelectedMic);
								await props.roomClient.disableMic();
								await props.roomClient.enableMic();
							}
							props.toCloseModal();
						}}
						>Save</button>

					
					</div>
				</div>
			</div>
		</div>

	);
};

const mapStateToProps = (state) => 
{
	return {
		currentSelectedWebcams:state.MeetingControl.selectedWebcamId,
		selectedMic : state.MeetingControl.selectedMicId,
		shareInProgress:state.me.shareInProgress
	};
};

const mapDispatchToProps = (dispatch) => 
{
	return {
		updateWebcamId : (id) => 
		{
			return dispatch(stateActions.setSelectedWebcamId(id));
		},

		updateMicId : (id) => 
		{
			return dispatch(stateActions.setSelectedMicId(id));
		}
	};
};

const DeviceSettingContainer = withRoomContext(connect(mapStateToProps, mapDispatchToProps)(DeviceSetting));

export default DeviceSettingContainer;
