import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './pinScreen.scss';
import * as stateActions from '../../../Redux/stateActions';
import PeerView from '../PeerView/PeerView';
import Peer from '../Peer/Peer';

const PinScreen = (props) => {
    console.log(props);

    useEffect(()=>{
        if(!props.pinnedVideo){
            props.setPinId(null);
        }
        console.log(props.pinnedVideo,'this is execute on change in the pinned video');
    },[props.pinnedVideo])
    return <div className='pinScreenContainer'>
        {
        props.isMe ?
        <div className='borderPeer'>
        <PeerView 
          isMe
          peer={props.me}
          audioProducerId={props.audioProducer ? props.audioProducer.id : null}
          videoProducerId={props.pinnedVideo ? props.pinnedVideo.id : null}
          audioRtpParameters={props.audioProducer ? props.audioProducer.rtpParameters : null}
          videoRtpParameters={props.pinnedVideo ? props.pinnedVideo.rtpParameters : null}
          audioTrack={props.audioProducer ? props.audioProducer.track : null}
          videoTrack={props.pinnedVideo ? props.pinnedVideo.track : null}
          videoVisible={Boolean(props.pinnedVideo )&& (!props.pinnedVideo.paused)}
          audioCodec={props.audioProducer ? props.audioProducer.codec : null}
          videoCodec={props.pinnedVideo ? props.pinnedVideo.codec : null}
          audioScore={props.audioProducer ? props.audioProducer.score : null}
          videoScore={props.pinnedVideo ? props.pinnedVideo.score : null}
        /></div>
          :
          props.pinnedVideo&&<div className='borderPeer'><Peer pinned={true} id={props.pinnedVideo.peerId} videoConsumerId={props.pinnedVideo.id}/></div>
        }
    </div>
}

const mapStateToProps=(state,props)=>{
    const me=state.me;
    const peersArray=Object.keys(state.peers);
    const audioProducer=Object.values(state.producers).find(producer=>producer.track.kind==='audio');
    const consumerVideoArray=Object.values(state.consumers).filter(consumer=>consumer.track.kind==='video');
    const producerVideoArray=Object.values(state.producers).filter(producer=>producer.track.kind==='video');
    const videoArray=[...consumerVideoArray,...producerVideoArray];
    const pinnedVideo=videoArray.find(video=>video.id===props.pinnedId);
    let presentPeer=peersArray.includes(pinnedVideo?.peerId);
    const isMe=pinnedVideo?.peerId===state.me.id;
    console.log(pinnedVideo?.peerId,state.me.id);
    
    if (!isMe && !presentPeer) {
        return {
            isMe,
            me,
            audioProducer,
        }
    }
    
    return {
        isMe,
        me,
        audioProducer,
        pinnedVideo
    }
}

const mapDispatchToProps=(dispatch)=>{
    return {
        setPinId:(id)=>dispatch(stateActions.setPinId(id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PinScreen);