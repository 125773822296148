import './App.css';
import UrlParse from 'url-parse';
import { connect } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as stateActions from './lib/Redux/stateActions';
import RoomClient from './RoomClient';
import RoomContext from './RoomContext';
import deviceInfo from './deviceInfo';
import { useEffect, useState } from 'react';
import property from './property-settings';
import MeetingControllerContainer from './lib/Components/MeetingController/meetingController';
import Test from './lib/Components/Test';

function App(props) {
  const [roomClient,setRoomCLient]=useState(null);
  console.log(process.env,property);

  // const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // // const reduxMiddlewares = [ thunk ]; 
  // const store = createReduxStore(
  //   reducers,
  //   composeEnhancer(applyReduxMiddleware(thunk))
  // );

  // RoomClient.init(store);

  useEffect(() => {

    // console.log('aditya');
    const urlParser = new UrlParse(window.location.href, true);
    // const peerId = randomString({ length: 12 }).toLowerCase();
    const peerId = urlParser.query.userId;
    let roomId = urlParser.query.roomId ;
    let displayName = urlParser.query.displayName;
    const handler = urlParser.query.handler;
    const useSimulcast = false;
    const useSharingSimulcast = property.useScreenShareHDQuality ? false : true;
    const forceTcp = urlParser.query.forceTcp === 'true';
    const produce = urlParser.query.userId==='bot-recorder'?false:true;
    const consume = urlParser.query.consume !== 'false';
    const forceH264 = urlParser.query.forceH264 === 'true';
    const forceVP9 = urlParser.query.forceVP9 === 'true';
    const svc = urlParser.query.svc;
    const datachannel = urlParser.query.datachannel !== 'false';
    const info = urlParser.query.info === 'true';
    const faceDetection = urlParser.query.faceDetection === 'true';
    const externalVideo = urlParser.query.externalVideo === 'true';
    const throttleSecret = urlParser.query.throttleSecret;
    const e2eKey = urlParser.query.e2eKey;
    
    
    const device = deviceInfo();
    
    let roomClient = new RoomClient(
      {
        roomId,
        peerId,
        displayName,
        device,
        handlerName : handler,
        useSimulcast,
        useSharingSimulcast,
        forceTcp,
        produce,
        consume,
        forceH264,
        forceVP9,
        svc,
        datachannel,
        externalVideo,
        e2eKey
      });

      setRoomCLient(roomClient);
      console.log(roomClient);
  }, []);

  useEffect(()=>{
    const roomUrlParser = new UrlParse(window.location.href, true);
    let roomUrl=roomUrlParser.toString();
    props.updateRoomUrl(roomUrl);
    // console.log(roomUrl); 
  });

  // console.log(roomClient);
  return (
    // <BrowserRouter>
    //   <RoomContext.Provider value={roomClient}>
    //     <Routes>
    //       <Route path='/join' element={<MeetingControllerContainer />} />
    //       <Route path='/createMeeting' element={<Test />}></Route>
    //       <Route path='/login' element={<Test login={true} />}/>
    //     </Routes>
    //   </RoomContext.Provider>
    // </BrowserRouter>   

    <RoomContext.Provider value={roomClient}>
      <MeetingControllerContainer />
    </RoomContext.Provider>
  );
}

const mapStateToProps=(state)=>{};

const mapDispatchToProps=(dispatch)=>{
  return {
    updateRoomUrl:(url)=>{
      return dispatch(stateActions.setRoomUrl(url));
    }
  }
};

export default connect(null,mapDispatchToProps)(App);
