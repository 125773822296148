import React from 'react';
import './room.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import * as appPropTypes from '../../appPropTypes';
import * as stateActions from '../../Redux/stateActions';
import { withRoomContext } from '../../../RoomContext';
import * as requestActions from '../../Redux/requestActions';
import Me from './Me/Me';
import Peers from './Peers/Peers';
import Notifications from '../Notification/Notifications';
import property from '../../../property-settings';
import Modal from '@material-ui/core/Modal';
import Menu from '@material-ui/core/Menu';
import DeviceSetting from './DeviceSetting/DeviceSetting';
import Spinner from '../../../assets/Spinner.svg';
import Logo from '../../../assets/icons/Logo-LSMeet.svg';
import { MenuItem } from '@material-ui/core';
import ConfirmDialog from '../../utils/Dialog';

// let timeout;


class Room extends React.Component {

	constructor(props) {
		super(props);
		this._isMounted = false;
		this.timeout='';
		// let fullScreenEvent; 
		let time = new Date(props.meetingControl.serverCurrentTime) - new Date(props.meetingControl.meetingStartTime);
		this.state = {
			timeState: time,
			mouseMoving: true,
			openSideOverlay: false,
			openModal: false,
			openMultipleVideoControl:false,
			openStopSharingDialogBox:false,
		};

		this.setMouseMove = this.setMouseMove.bind(this);
		this.setOpenOverlay = this.setOpenOverlay.bind(this);
		this.setOpenModal = this.setOpenModal.bind(this);
		this.setOpenMultipleVideo=this.setOpenMultipleVideo.bind(this);
		this.setOpenStopSharingDialogBox=this.setOpenStopSharingDialogBox.bind(this);
		this.stopSharingConfirmedTrue=this.stopSharingConfirmedTrue.bind(this);

		this.multipleVideoControlButtonRef=React.createRef();
	}

	

	setOpenMultipleVideo(){
		console.log(this.state,'opening list items');
		let currentOpenMultipleVideo = this.state.openMultipleVideoControl;
		this._isMounted && this.setState({...this.state,openMultipleVideoControl:!currentOpenMultipleVideo});
	}

	setOpenModal() {
		console.log(this.state,'open modal device setting');
		let currentModalState = this.state.openModal;
		this._isMounted && this.setState({ ...this.state, openModal: !currentModalState });
	}
	setMouseMove(e) {
		this._isMounted && this.setState({ ...this.state, mouseMoving: true });
		let duplicateTimeout;
		duplicateTimeout = setTimeout(() => this._isMounted && this.setState({ ...this.state, mouseMoving: false,openMultipleVideoControl:false }), 3000);
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = duplicateTimeout;
		// (() => {
		// 	clearTimeout(timeout2);
		// 	timeout = setTimeout(() => this.setState({...this.state , mouseMoving : false}) , 3000)
		// 	console.log('1234ewksfj skdlfjasdklf ksdjfkladsjf kdjfaklsdjflkdsf lksdjfakldsfj dsklafjlkdsfj asdlkjfakldsfaklsdf sdkljfkladsjfkladsf kldsjaf lkdjfkalsdf ', this.state)

		// })(); 
	}

	setOpenStopSharingDialogBox(){
		// alert('room.jsx function is called');
		let currentDialogBoxState = this.state.openStopSharingDialogBox;
		this._isMounted && this.setState({ ...this.state, openStopSharingDialogBox:!currentDialogBoxState });
	}

	setOpenOverlay() {
		if (this.state.openSideOverlay) {
			this._isMounted && this.setState({ ...this.state, openSideOverlay: false })
		} else {
			this._isMounted && this.setState({ ...this.state, openSideOverlay: true })
		}
	}

	stopSharingConfirmedTrue(){
		this.props.roomClient.disableShare();
	}
	// function displayTimeFormat(milisec) {
	// 	let seconds=(milisec/1000).toFixed(0);
	// 	let minutes=Math.floor(seconds/60);
	// 	let hours="";
	// 	if(minutes>59){
	// 		hours=Math.floor(minutes/60);
	// 		hours=(hours>10)?hours:'0'+hours;
	// 		minutes=minutes-(hours*60);
	// 		minutes=(minutes>10)?minutes:'0'+minutes;
	// 	}

	// 	seconds=Math.floor(seconds%60);
	// 	seconds=(seconds>=10)?seconds:'0'+seconds;
	// 	if(hours!=''){
	// 		return hours+':'+minutes+':'+seconds;
	// 	}
	// 	return minutes+':'+seconds;
	// }

	render() {
		const {
			roomClient,
			room,
			me,
			amActiveSpeaker,
			// onRoomLinkCopy,
			onLeavingMeeting,
			meetingControl,
			audioProducer,
			videoProducer,
			onStatsClick,
			endMeeting,
			isLoading,
		} = this.props;

		// console.log(roomClient);
		let micState;
		let webcamState;
		// let changeWebcamState;
		let shareState;
		// const videoVisible = Boolean(videoProducer) && !videoProducer.paused;

		if (!me.canSendMic) {
			micState = 'unsupported';
		} else if (!audioProducer) {
			micState = 'unsupported';
		} else if (!audioProducer.paused) {
			micState = 'on';
		} else {
			micState = 'off';
		}

		if (!me.canSendWebcam) {
			webcamState = 'unsupported';
		} else if (videoProducer && videoProducer.type !== 'share') {
			webcamState = 'on';
		} else {
			webcamState = 'off';
		}

		// if (Boolean(videoProducer) && videoProducer.type !== 'share' && me.canChangeWebCam) {
		// 	changeWebcamState = 'on';
		// } else {
		// 	changeWebcamState = 'off';
		// }

		if (Boolean(videoProducer) && videoProducer.type === 'share') {
			shareState = 'on';
		} else {
			shareState = 'off';
		}

		// const currentTime=new Date(meetingControl.serverCurrentTime)-new Date(meetingControl.meetingStartTime);

		// const currentTime=new Date() - new Date(meetingControl.serverCurrentTime);

		function displayTimeFormat(milisec) {
			let seconds = (milisec / 1000).toFixed(0);
			let minutes = Math.floor(seconds / 60);
			let hours = "";
			if (minutes > 59) {
				hours = Math.floor(minutes / 60);
				hours = (hours > 10) ? hours : '0' + hours;
				minutes = minutes - (hours * 60);
				minutes = (minutes > 10) ? minutes : '0' + minutes;
			}

			seconds = Math.floor(seconds % 60);
			seconds = (seconds >= 10) ? seconds : '0' + seconds;
			if (hours !== '') {
				return hours + ':' + minutes + ':' + seconds;
			}
			return minutes + ':' + seconds;
		}

		const timeFormat = this._isMounted && displayTimeFormat(this.state.timeState);
	// 	const loaderStyle = {
	// 		width: "64px",
	// 		margin: "auto",
	// 		height: "64px",
	// 		position: "relative",
	// 		backgroundImage: "linear-gradient(#F1F1F1 16px, transparent 0),linear-gradient(#0197D6 16px,transparent 0),linear-gradient(#0197D6 16px, transparent 0), linear-gradient(#F1F1F1 16px, transparent 0)",
	// 		backgroundRepeat: "no-repeat",
	// 		backgroundSize: "16px 16px",
	// 		backgroundPosition: "left top, left bottom, right top, right bottom",
	// 		animation: "rotate 1s linear infinite",
	// }

	return(
			<>
			<div className='Room' onMouseMove={(event) => this.setMouseMove(event)}>
				<Notifications  shiftNotification={this.state.openSideOverlay} />
				{isLoading && <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent', position: 'absolute', top: '0', right: '0', left: '0', bottom: '0', backdropFilter: 'blur(6px)', zIndex: '100' }}>
					<img src={Spinner } alt='Wait for a Moment' style={{backgroundColor:'transparent'}} />
					{/* <div style={loaderStyle}></div> */}
				</div>}

				{/* {isLoading && <img src="../../resources/loader.gif" style={{backgroundColor:'transparent', position:'absolute',top:'0',right:'0',left:'0',bottom:'0', zIndex:'100'}} /> } */}
				{/* <div className='state'>
						<div className={classnames('icon', room.state)} />
						<p className={classnames('text', room.state)}>{room.state} {timeFormat}</p>
					</div>

					{/* <div className='room-link-wrapper'>
						<div className='room-link'>
							<a
								className='link'
								href={room.url}
								target='_blank'
								rel='noopener noreferrer'
								onClick={(event) => {
									// If this is a 'Open in new window/tab' don't prevent
									// click default action.
									if (
										event.ctrlKey || event.shiftKey || event.metaKey ||
										// Middle click (IE > 9 and everyone else).
										(event.button && event.button === 1)
									) {
										return;
									}

									event.preventDefault();

									clipboardCopy(room.url)
										.then(onRoomLinkCopy);
								}}
							>
								{meetingControl.meetingTitle}
							</a>
						</div>
					</div> */}
				<Peers settimeformat={`${timeFormat}`}>
					<div style={amActiveSpeaker ? { zIndex:'11', border: '4px solid #0197D6', borderRadius: '7px 7px 0 0',display:'flex' } : {zIndex:'11', border: '4px solid #fff', borderRadius: '7px 7px 0 0',display:'flex' }}
						className={classnames('me-container', {
							'active-speaker': amActiveSpeaker
						})}
					>
						<Me />
					</div>
				</Peers>

				{/* <div
						className={classnames('me-container', {
							'active-speaker': amActiveSpeaker
						})}
					>
						<Me />
					</div> */}

				{/* <div className='chat-input-container'>
						<ChatInput />
					</div> */}

				{/* <div className='sidebar'> */}
				{/* <div
							className={classnames('button', 'hide-videos', {
								on: me.audioOnly,
								disabled: me.audioOnlyInProgress
							})}
							data-tip={'Show/hide'}
							onClick={() => {
								me.audioOnly
									? roomClient.disableAudioOnly()
									: roomClient.enableAudioOnly();
							}}
						/> */}

				{/* <div
							className={classnames('button', 'mute-audio', {
								on: me.audioMuted
							})}
							data-tip={'Mute/unmute'}
							onClick={() => {
								me.audioMuted
									? roomClient.unmuteAudio()
									: roomClient.muteAudio();
							}}
						/> */}
				{/* <div
							className={classnames('button', 'end-meeting', {
								on: me.audioOnly,
								disabled: me.audioOnlyInProgress
							})}
							data-tip={'Leave Meeting'}
							onClick={() => {
								onLeavingMeeting();
								roomClient.close();
							}}
						/>
						{
							me.id == meetingControl.presenterId && <div
								className={classnames('button', 'record-meeting', {
									on: me.audioOnly,
									disabled: me.audioOnlyInProgress
								})}
								data-tip={'Record Meeting'}
								onClick={() => {
									me.audioOnly
										? roomClient.disableAudioOnly()
										: roomClient.enableAudioOnly();
								}}
							/>
						} */}


				{/* <div
							className={classnames('button', 'restart-ice', {
								disabled: me.restartIceInProgress
							})}
							data-tip='Restart ICE'
							onClick={() => roomClient.restartIce()}
						/> */}
				{/* </div> */}

				{/* <Stats /> */}

				{(this._isMounted && window.NETWORK_THROTTLE_SECRET)&&
					{/* <NetworkThrottle
						secret={window.NETWORK_THROTTLE_SECRET}
					/> */}
				}

				<div style={{ width: this.state.openSideOverlay ? '75%' : '100%', left: 0, display: 'flex', justifyContent: 'center', transition: '0.5s', transform: this.state.mouseMoving ? 'translateY(0)' : 'translateY(100%)', visibility: this.state.mouseMoving ? 'visible' : 'hidden' }} className='controls'>
					<div style={{ position: 'absolute', left: '9px' }} >
						<img src={Logo} alt="lsmeet logo" height="43px" />
					</div>

					<div className={classnames('button', { 'maximize': !this.state.openSideOverlay, 'minimize': this.state.openSideOverlay })}
						onClick={() => {
							onStatsClick(me.id);
							this.setOpenOverlay();
						}}
						data-tip={'Show/Hide Participants'}
					>
						<div className='image-container'></div>
					</div>

					<div
						className={classnames('button', 'mic', micState)}
						data-tip={'Mute/Unmute Mic'}
						onClick={() => {
							if (micState === 'on') {
								roomClient.muteMic();
								this.props.updateMicState(!meetingControl.initialStateOfMic);
							} else {
								roomClient.unmuteMic();
								this.props.updateMicState(!meetingControl.initialStateOfMic);
							}
						}}
					>
						<div className='image-container'></div>

					</div>

					{
						this.props.videoDevicesPermission.length<=1?
						<div className={classnames('button', 'webcam', webcamState, {
							// disabled: me.webcamInProgress || me.shareInProgress
						})}
							data-tip={'Show/Hide Camera'}
							onClick={async () => {
								if (webcamState === 'on') {
									roomClient.disableWebcam();
									this.props.updateWebcamState(!meetingControl.initialStateOfWebcam);
								}
								else {
									if(me.shareInProgress){
										this.setOpenStopSharingDialogBox(); //to open dialog box to ask for weather to stop screen sharing or not if screen is shared.
									}else {
										await roomClient.enableWebcam();
										this.props.updateWebcamState(!meetingControl.initialStateOfWebcam);
									}
									
								}
							}}
						>
							<div className='image-container'></div>
						</div>
						:
							<div ref={this.multipleVideoControlButtonRef} className={classnames('button', 'webcam', webcamState, 'multi',{
								disabled: me.webcamInProgress || me.shareInProgress
							})}

								data-tip={'Show/Hide Camera'}
								onClick={this.setOpenMultipleVideo}
							>
								<div className='image-container'></div>
							</div>
					}	<Menu onClick={(event)=>{
							if(!Array.from(event.target.childNodes).length){
								this.setOpenMultipleVideo();
							}}} 
							open={this.state.openMultipleVideoControl} 
							anchorEl={this.multipleVideoControlButtonRef.current}  
							anchorOrigin={{vertical:'top',horizontal:'right',}} 
							transformOrigin={{vertical:'bottom',horizontal:'left'}}>
									{
										this.props.videoDevicesPermission&&this.props.videoDevicesPermission.map(camera => {
											return <MenuItem style={roomClient._videoProducers.get(camera.cameraId)?{opacity:'1',color:'#003D58'}:{opacity:'0.5',color:'red'}} key={camera.cameraId} data-value={camera.cameraId} value={camera.cameraId} onClick={async (event)=>{
												this.setOpenMultipleVideo();
												console.log(event.target.dataset.value);
												console.log(event.target.value);
												if(!roomClient._videoProducers.get(event.target.dataset.value)){
													console.log('call for turning this particular camera on',event.target.dataset.value);
													await roomClient.enableParticularWebcam(event.target.dataset.value);
												}else{
													await roomClient.disableParticularWebcam(event.target.dataset.value);
												}
												
											}}>{camera.label}
											</MenuItem>
										})
									}
						</Menu>
					{/* {
							me.id == meetingControl.presenterId && <div
								className={classnames('button', 'change-webcam', changeWebcamState, {
									disabled: me.webcamInProgress || me.shareInProgress
								})}
								onClick={() => roomClient.changeWebcam()}
							/>
						} */}

					{
						me.id === meetingControl.presenterId && <div
							className={classnames('button', 'end-meeting')}
							data-tip={'End Meeting For All'}
							onClick={() => {
								endMeeting();
							}}
						>
							<div className='image-container'></div>
						</div>
					}

					<div
						className={classnames('button', 'fullScreen', room.fullScreenAllowed ? 'off' : 'on')}
						data-tip={room.fullScreenAllowed ? 'Exit Full Screen' : 'Full Screen'}
						onClick={() => this.props.setFullScreen(!room.fullScreenAllowed)}
					>
						<div className='image-container'></div>

					</div>

					{
					(property.view === 1 ||	me.id === meetingControl.presenterId) &&	<div
							className={classnames('button', 'share', shareState, {
								// disabled: me.shareInProgress || me.webcamInProgress
							})}
							data-tip={'Share Screen'}
							onClick={() => {
								if (shareState === 'on')
									roomClient.disableShare();

								else
									roomClient.enableShare();

							}}
						>
							<div className='image-container'></div>
						</div>
					}
					<div
						className={classnames('button', 'leave-meeting')}
						data-tip={'Leave Meeting'}
						onClick={() => {
							// roomClient.close();
							onLeavingMeeting()
						}}
					>
						<div className="image-container"></div>
					</div>
					{
						(me.id === meetingControl.presenterId && property.recordingAllowed) ? <div

							className={classnames('button', 'record-meeting', {
								on: me.audioOnly,
								disabled: me.audioOnlyInProgress
							})}
							data-tip={'Record Meeting'}
						// onClick={() => {
						// 	me.audioOnly
						// 		? roomClient.disableAudioOnly()
						// 		: roomClient.enableAudioOnly();
						// }}
						>
							<div className="image-container"></div>
						</div> : null
					}

					{
						property.whiteBoardAllowed ?
							<div className={classnames('button', 'whiteboard')}
								data-tip={'Whiteboard'}
							>
								<div className="image-container"></div>
							</div> : null
					}

					{
						me.id !== meetingControl.presenterId&& <div className={classnames('button', 'handRaise')}
						data-tip={'Raise Hand'}
						onClick={()=>{
							roomClient.handRaiseByParticipant();
						}}
					>
						<div className="image-container"></div>
					</div>
					}

					{
						me.id === meetingControl.presenterId && <div className={classnames('button', 'setting')}
							data-tip={'Settings'}
							onClick={this.setOpenModal}
						>
							<div className="image-container"></div>
						</div>
					}
					


					{/* <div style={{ height: '20px', 'width': '20px', display: 'none' }} >white board</div> */}
				</div>

				<Modal aria-labelledby='simple-modal' aria-describedby='simple-modal-desc' open={this.state.openModal} onClose={this.setOpenModal}>
					<div style={{ position: 'relative',top: 'calc(50% - 22.5vh)',borderRadius : '8px' , backgroundColor : '#FFF' , boxShadow : '0 10px 6px rgb(0, 0, 0, 0.16)' , margin: 'auto', height: '55vh', width: '55vw' }}>
						<DeviceSetting toCloseModal={this.setOpenModal} />
					</div>
				</Modal>

				<ConfirmDialog 
					title={'Stop Sharing'} 
					open={this.state.openStopSharingDialogBox} 
					setOpen={this.setOpenStopSharingDialogBox} 
					onConfirm={this.stopSharingConfirmedTrue} 
					confirmButtonTitle='Stop Screen Sharing'
					// titleStyle={{borderTopLeftRadius:'20px',borderTopRightRadius:'20px'}}
				><h4>You are currently sharing your screen. Do you want to stop?</h4></ConfirmDialog>

				<ReactTooltip
					type='info'
					effect='solid'
					multiline
					delayShow={100}
					delayHide={100}
					delayUpdate={50}
				/>
			</div>
			</>
			
		);
	}

updateTimer() {
	// console.log(this.state.timeState, typeof (this.state.timeState));
	this._isMounted && this.setState({
		timeState: this.state.timeState + 1000,
	});
}

componentDidMount() {
	this._isMounted = true;
	const { roomClient } = this.props;

	roomClient._displayName = this.props.me.displayName;

	roomClient.join();

	this.timerId = this._isMounted && setInterval(() => this.updateTimer(), 1000);
	// fullScreenEvent=addEventListener('fullScreenChange',(event)=>{
	// 	console.log(event);
	// });
	let fullBody = document.getElementById('root');
	fullBody.addEventListener('fullscreenchange',(event)=>{
		if(document.fullscreenElement){
			this.props.setFullScreen(true);
		}else{
			this.props.setFullScreen(false);
		}
	})
}


componentDidUpdate(prevProps) {
	if (this.props.room.fullScreenAllowed !== prevProps.room.fullScreenAllowed ) {
		const fullBody = document.getElementById('root');
		if (this.props.room.fullScreenAllowed) {
			fullBody.requestFullscreen();
		} else {
			document.fullscreenElement && document.exitFullscreen();
		}

	// 	// console.log(fullBody, '123');
	}
}

componentWillUnmount() {
	this._isMounted = false;
	clearInterval(this.timerId);
	this.props.roomClient.close();
	let fullBody = document.getElementById('root');
	fullBody.removeEventListener('fullscreenchange',(event)=>{
		if(document.fullscreenElement){
			this.props.setFullScreen(true);
		}else{
			this.props.setFullScreen(false);
		}
	});
}
}

Room.propTypes =
{
	roomClient: PropTypes.any.isRequired,
	room: appPropTypes.Room.isRequired,
	me: appPropTypes.Me.isRequired,
	amActiveSpeaker: PropTypes.bool.isRequired,
	onRoomLinkCopy: PropTypes.func.isRequired,
	onLeavingMeeting: PropTypes.func
};

const mapStateToProps = (state) => {
	const producersArray = Object.values(state.producers);
	const audioProducer = producersArray.find((producer) => producer.track.kind === 'audio');
	const videoProducer = producersArray.find((producer) => producer.track.kind === 'video');
	const videoDevicesPermission=state.MeetingControl.selectedWebcamId;
	// console.log(videoDevicesPermission);
	return {
		room: state.room,
		me: state.me,
		meetingControl: state.MeetingControl,
		audioProducer: audioProducer,
		videoProducer: videoProducer,
		amActiveSpeaker: state.me.id === state.room.activeSpeakerId,
		isLoading: state.room.isLoading,
		videoDevicesPermission,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onRoomLinkCopy: () => {
			dispatch(requestActions.notify(
				{
					text: 'Room link copied to the clipboard'
				}));
		},

		onLeavingMeeting: () => {
			dispatch(stateActions.setRoomIsValid(
				{
					flag: false
				}
			));
		},

		onStatsClick: (id) => {
			dispatch(stateActions.setRoomStatsPeerId(id));
		},

		setFullScreen: (flag) => {
			dispatch(stateActions.setFullScreenAllowed(flag));
		},

		updateWebcamState: (flag) => {
			return dispatch(stateActions.setInitalStateOfWebcam(flag));
		},

		updateMicState: (flag) => {
			return dispatch(stateActions.setInitalStateOfMic(flag));
		}
	};
};

const RoomContainer = withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps
)(Room));

export default RoomContainer;
