/* eslint-disable no-mixed-operators */
/* eslint-disable react/react-in-jsx-scope */
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import UrlParse from 'url-parse';
import useFetchapi from "../../../utils/useFetchapi";
import pdficon from '../../../../assets/icons/Pdficon.svg';
import docIcon from '../../../../assets/icons/microsoft-word.svg';
import xlsIcon from '../../../../assets/icons/microsoft-excel.svg';
import pptIcon from '../../../../assets/icons/microsoft-powerpoint.svg';

let anchorTag = {
  textDecoration : 'none',
  color : "#000",
  padding : '4px 4px 0 4px'
}

const ChatBox = ({ chatData , presenterId , currentId }) => {
	const setScrollRef = useRef(null);
	console.log(presenterId, currentId)
	let body = {};
  const urlParser = UrlParse(window.location.href, true);
	// if(presenterId == currentId){
		const { isLoading, apiData, serverError } = useFetchapi(
		  "GET",
		  "/getMeetingChat",
		  { meetingId: urlParser.query.roomId },
		  body,
		  presenterId === currentId ? true : false
		);
	// } 
  const [previousChatData, setPreviousChatData] = useState([]);
  useEffect(() => {
    let chatArr = [];
    if (apiData?.chatList?.length) {
      apiData.chatList.forEach((data, index) => {
        if (
          chatArr.length &&
          chatArr[chatArr.length - 1]?.id === data.participantId
        ) {
          let lastMsg = chatArr[chatArr.length - 1];
          lastMsg.text = [
            ...lastMsg?.text,
            {
              msg: data.chatMessage || data.fileUrl,
              timeStamp: new Date(data.createTime).toLocaleTimeString("en-US", {
                hour12: "true",
                hour: "numeric",
                minute: "numeric",
              }),
              type : data.fileType,
              name : data.fileName
            },
          ];
          chatArr[chatArr.length - 1] = lastMsg;
        } else {
          chatArr.push({
            id: data.participantId,
            title: data.participantName ? data.participantName : "Anonymous",
            text: [
              {
                msg: data.chatMessage || data.fileUrl,
                timeStamp: new Date(data.createTime).toLocaleTimeString(
                  "en-US",
                  { hour12: "true", hour: "numeric", minute: "numeric" }
                ),
                type : data.fileType,
                name : data.fileName
              },
            ],
          });
        }
      });
    }
    setPreviousChatData(chatArr);
  }, [apiData]);

  useEffect(() => {
	setScrollRef.current.scrollTop = setScrollRef.current.scrollHeight;
  }, [chatData, previousChatData])

  return (
    <>
	  <div ref={setScrollRef} style={{ height: "100%", width: "100%", overflow: "auto" }}>
        {previousChatData.map((message) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "9px",
                alignItems: presenterId != message.id ? "flex-start" : "flex-end",
				paddingRight : '8px'
              }}
              key={`${message.title + Math.random() * 1000}`}
            >
              {" "}
              {message.title !== "Me" && (
                <p
                  style={{
                    marginTop: "0",
                    marginBottom: "3px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {presenterId != message.id && message.title}
                </p>
              )}
              {message.text.map((msg) => {
                return (
                  <span
                    key={`${msg.timeStamp + Math.random() * 1000}`}
                    style={{
                      backgroundColor:
					  presenterId != message.id ? "#aedbff" : "#dddede",
                      position: "relative",
                      fontSize: "14px",
                      lineHeight: "19px",
                      color: "#000",
                      width: "fit-content",
                      borderRadius: "5px",
                      padding: "5px",
                      fontWeight: "400",
                      marginBottom: "7px",
                      maxWidth: "85%",
                      display: "flex",
                      boxShadow: "0 1px 3px rgb(0 0 0 / 16%)",
                    }}
                  >
                    <span style={{ wordBreak: "break-all" }}>
                      
                      {
                        (msg?.type === "audio/gg") && <audio style={{width : '216px'}} controls src={msg.msg}></audio>
                      }
                      {
                        (msg?.type === 'image/png' || msg?.type === 'image/jpeg') && <a href={msg.msg} alt='image' target="_blank"><img src={msg.msg} alt={msg.name} height={'200px'} width={'200px'} ></img></a> 
                      }
                      {
                        (msg?.name?.includes('.xls') ||  msg?.name?.includes('.xlsx') ) && <span style={{display : 'flex'}}> <img src={xlsIcon} height={'32px'} width={'32px'} alt="" ></img> <a  style={anchorTag} href={msg.msg} target="_blank" >{msg.name}</a> </span> 
                      }
                      {
                        msg?.type === 'application/pdf' && <span style={{display : 'flex'}}> <img src={pdficon} height={'32px'} width={'32px'} alt="" ></img> <a  style={anchorTag} href={msg.msg} target="_blank" >{msg.name}</a> </span>  
                      }
                      {
                        ( msg?.name?.includes('.docx') ||  msg?.name?.includes('.doc') ) && <span style={{display : 'flex'}}> <img src={docIcon} height={'32px'} width={'32px'} alt="" ></img> <a  style={anchorTag} href={msg.msg} target="_blank" >{msg.name}</a> </span>
                      }
                      {
                       ( msg?.name?.includes('.ppt') || msg?.name?.includes('.pptx')) && <span style={{display : 'flex'}}> <img src={pptIcon} height={'32px'} width={'32px'} alt="" ></img> <a  style={anchorTag} href={msg.msg} target="_blank" >{msg.name}</a> </span> 
                      }
                      {
                         msg?.name?.includes('.odt') && <span style={{display : 'flex'}}> <img src={docIcon} height={'32px'} width={'32px'} alt="" ></img> <a  style={anchorTag} href={msg.msg} target="_blank" >{msg.name}</a> </span> 
                      }
                      
                      {
                        !msg?.type && msg.msg
                      }
                      <span
                        style={{
                          fontSize: "10px",
                          display: "flex",
                          color: "#545353", 
                          alignItems: "center",
                          justifyContent: "end",
                          top: "2px",
                          right: "0",
                          position: "relative",
                        }}
                      >
                        {msg.timeStamp}
                      </span>
                    </span>
                  </span>
                );
              })}
            </div>
          );
        })}

        {chatData.map((message) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "9px",
                alignItems: message.title != "Me" ? "flex-start" : "flex-end",
				paddingRight : '8px'
              }}
              key={`${message.title + Math.random() * 1000}`}
            >
              {" "}
              {message.title !== "Me" && (
                <p
                  style={{
                    marginTop: "0",
                    marginBottom: "3px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {message.title != "Me" && message.title}
                </p>
              )}
              {message.text.map((msg) => {
                return (
                  <span
                    key={`${msg.timeStamp + Math.random() * 1000}`}
                    style={{
                      backgroundColor:
                        message.title != "Me" ? "#aedbff" : "#dddede",
                      position: "relative",
                      fontSize: "14px",
                      lineHeight: "19px",
                      color: "#000",
                      width: "fit-content",
                      borderRadius: "5px",
                      padding: "5px",
                      fontWeight: "400",
                      marginBottom: "7px",
                      maxWidth: "85%",
                      display: "flex",
                      boxShadow: "0 1px 3px rgb(0 0 0 / 16%)",
                    }}
                  >
                    <span style={{ wordBreak: "break-all" }}>
                      {
                        (msg?.type === "audio/gg") && <audio style={{width : '216px'}} controls src={msg.msg}></audio> 
                      }
                      {
                        (msg?.type === 'image/png' || msg?.type === 'image/jpeg') && <a href={msg.msg} alt='image' target="_blank"><img src={msg.msg} alt={msg.name} height={'200px'} width={'200px'} ></img></a> 
                      }
                      {
                        (msg?.name?.includes('.xls') ||  msg?.name?.includes('.xlsx') ) && <span style={{display : 'flex'}} > <img src={xlsIcon} height={'32px'} width={'32px'} alt="" ></img> <a  style={anchorTag} href={msg.msg} target="_blank" >{msg.name}</a> </span> 
                      }
                      {
                        msg?.type === 'application/pdf' && <span style={{display : 'flex'}} > <img src={pdficon} height={'32px'} width={'32px'} alt="" ></img> <a  style={anchorTag} href={msg.msg} target="_blank" >{msg.name}</a> </span>  
                      }
                      {
                        ( msg?.name?.includes('.docx') ||  msg?.name?.includes('.doc') ) && <span style={{display : 'flex'}} > <img src={docIcon} height={'32px'} width={'32px'} alt="" ></img> <a  style={anchorTag} href={msg.msg} target="_blank" >{msg.name}</a> </span>
                      }
                      {
                       ( msg?.name?.includes('.ppt') || msg?.name?.includes('.pptx')) && <span style={{display : 'flex'}} > <img src={pptIcon} height={'32px'} width={'32px'} alt="" ></img> <a  style={anchorTag} href={msg.msg} target="_blank" >{msg.name}</a> </span> 
                      }
                      {
                         msg?.name?.includes('.odt') && <span style={{display : 'flex'}} > <img src={docIcon} height={'32px'} width={'32px'} alt="" ></img> <a  style={anchorTag} href={msg.msg} target="_blank" >{msg.name}</a> </span> 
                      }
                      {
                        !msg?.type && msg.msg
                      }
                      <span
                        style={{
                          fontSize: "10px",
                          display: "flex",
                          color: "#545353",
                          alignItems: "center",
                          justifyContent: "end",
                          top: "2px",
                          right: "0",
                          position: "relative",
                        }}
                      >
                        {msg.timeStamp}
                      </span>
                    </span>
                  </span>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { chatData } = state;


  return {
    chatData: chatData,
	presenterId : state.MeetingControl.presenterId,
	currentId : state.me.id
  };
};

const ChatBoxContainer = connect(mapStateToProps, null, null, {
  areStatesEqual: (next, prev) => {
    return prev.chatData === next.chatData;
  },
})(ChatBox);

export default ChatBoxContainer;
